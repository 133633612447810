import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

type ModalLayoutProps = {
  open: boolean;
  toggle: (open: boolean) => void;
  children: React.ReactNode;
};

const ModalLayout = ({ open, toggle, children }: ModalLayoutProps) => {
  return (
    <Modal open={open} onClose={toggle} sx={{ overflow: "scroll" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          px: 3,
          py: 1,
          width: 580,
          borderRadius: 2,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
export default ModalLayout;
