import { createBrowserRouter } from "react-router-dom";

import App, { ErrorPageWithLayout } from "./App";

import Search from "./containers/Search";
import ListsTableContainer from "./containers/Lists/Lists";
import ListPage from "./containers/Lists/ListPage";
import { Dashboard } from "./containers/Dashboard";
import Persons from "./containers/Persons";
import { getList } from "./services/ListsService";
import PersonSwitch from "./containers/Persons/Person";
import ContactsPage from "./containers/Persons/Contacts";
import EmailsPage from "./containers/Emails";
import EmailPage from "./containers/Emails/EmailPage";

export enum PATHS {
  SEARCH = "/search",
  LISTS = "/lists",
  PEOPLE = "/people",
  EMAILS = "/emails",
  EMAILS_SENT = "/emails/sent",
  EMAILS_DRAFT = "/emails/draft",
  EMAILS_ID = "/emails/:id",
  CONTACTS = "/contacts",
  PEOPLE_ID = "/people/:id",
  LISTS_ID = "/lists/:id",
  PERSON_EMAILS = "/people/:id/emails/:email_id",
  OWN_ACCOUNT = "/user_details",
  LOGOUT = "/signout",
  TWITTER_DASHBOARD = "/twitter",
  PUBLICATIONS = "/publications",
  BROADCAST_DASHBOARD = "/broadcast",
  BRIEFINGS_DASHBOARD = "/briefings",
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPageWithLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: PATHS.SEARCH,
        element: <Search />,
      },
      {
        path: PATHS.LISTS,
        element: <ListsTableContainer />,
      },
      {
        path: PATHS.PEOPLE,
        element: <Persons />,
      },
      {
        path: PATHS.EMAILS,
        element: <EmailsPage />,
      },
      {
        path: PATHS.EMAILS_SENT,
        element: <EmailsPage />,
      },
      {
        path: PATHS.EMAILS_DRAFT,
        element: <EmailsPage />,
      },
      {
        path: PATHS.EMAILS_ID,
        element: <EmailPage />,
        loader: async ({ params }) => {
          return { id: params.id };
        },
      },
      {
        path: PATHS.CONTACTS,
        element: <ContactsPage />,
      },
      {
        path: PATHS.PEOPLE_ID,
        element: <PersonSwitch />,
        loader: async ({ params }) => {
          return { id: params.id };
        },
      },
      {
        path: PATHS.LISTS_ID,
        element: <ListPage />,
        loader: async ({ params }) => {
          const data = await getList(params.id as string);
          return { id: params.id, list: data };
        },
      },
    ],
  },
]);
export default router;
