import Box from "@mui/material/Box";

import { GeoJSON as GeoJsonClass } from "leaflet";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { GeoJsonObject } from "geojson";
import { usePersonGeo } from "../../../query/person";
import { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

type ConstituencyMapProps = {
  constituencyId: number;
};

const ConstituencyMap = ({ constituencyId }: ConstituencyMapProps) => {
  const [loaded, setLoaded] = useState(false);
  const { data, isLoading } = usePersonGeo(constituencyId);
  const geoJsonData = useMemo(() => {
    if (data?.value) {
      return {
        type: "Feature",
        geometry: data.value,
      };
    }
  }, [data?.value]);

  const center = useMemo(() => {
    if (geoJsonData) {
      return new GeoJsonClass(geoJsonData as any).getBounds().getCenter();
    }
  }, [geoJsonData]);

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {data && center ? (
        <Box style={{ height: "400px", width: "800px" }} id="map">
          <MapContainer
            center={[center.lat, center.lng]}
            whenReady={() => setLoaded(true)}
            zoom={10}
            scrollWheelZoom={false}
            style={{ height: "400px", width: "800px" }}
          >
            {!loaded ? null : (
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            )}

            <GeoJSON data={data.value as GeoJsonObject} />
          </MapContainer>
        </Box>
      ) : null}
    </>
  );
};

export default ConstituencyMap;
