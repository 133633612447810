import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateRangePicker } from "./DatePicker";
import { useState } from "react";

const DateRangeSelect = ({ setValue }: any) => {
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [dateFieldValue, setDateFieldValue] = useState<string | null>(null);
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <FormControl disabled={dateFieldValue ? true : false} size="small">
        <InputLabel>Date</InputLabel>
        <OutlinedInput
          value={dateFieldValue ? dateFieldValue : ""}
          inputProps={{ "aria-label": "Without label" }}
          endAdornment={
            <InputAdornment position="end">
              <CalendarMonthIcon />
            </InputAdornment>
          }
          fullWidth={true}
          onClick={() => setDatepickerOpen(!datepickerOpen)}
          label="Date"
          sx={{
            width: 240,
          }}
        />
      </FormControl>
      <Box display={datepickerOpen ? "block" : "none"} pt={2}>
        <DateRangePicker
          open={datepickerOpen}
          toggle={() => setDatepickerOpen(!datepickerOpen)}
          onChange={(range) => {
            if (range.startDate === undefined && range.endDate === undefined) {
              setDateFieldValue("");
              setValue("date_from", "");
              setValue("date_to", "");
              return;
            } else {
              setValue(
                "date_from",
                range.startDate ? range.startDate?.toISOString() : "",
              );
              setValue(
                "date_to",
                range.endDate ? range.endDate?.toISOString() : "",
              );
              setDateFieldValue(
                `${range.startDate?.toLocaleDateString()} - ${range.endDate?.toLocaleDateString()}`,
              );
            }
          }}
        />
      </Box>
    </Box>
  );
};
export default DateRangeSelect;
