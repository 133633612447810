import { createTheme } from "@mui/material";

// primary?: PaletteColorOptions;
// secondary?: PaletteColorOptions;
// error?: PaletteColorOptions;
// warning?: PaletteColorOptions;
// info?: PaletteColorOptions;
// success?: PaletteColorOptions;
// mode?: PaletteMode;
// tonalOffset?: PaletteTonalOffset;
// contrastThreshold?: number;
// common?: Partial<CommonColors>;
// grey?: ColorPartial;
// text?: Partial<TypeText>;
// divider?: string;
// action?: Partial<TypeAction>;
// background?: Partial<TypeBackground>;
// | 'h1'
// | 'h2'
// | 'h3'
// | 'h4'
// | 'h5'
// | 'h6'
// | 'subtitle1'
// | 'subtitle2'
// | 'body1'
// | 'body2'
// | 'caption'
// | 'button'
// | 'overline';

const palette = {
  primary: {
    main: "#FF64A9",
    light: "#ff92c2",
  },
  secondary: {
    main: "#F9F8FC",
  },
  background: {
    default: "#FFFFFF",
  },
  common: {
    black: "#1D1D41",
    blackBlue: "#150E1F",
    white: "#fff",
    turqoise: "#00FFD1",
    lightgray: "#8E8EA0",
    ghostWhite: "#FBF9FE",
    grayOpacity: "rgba(255, 255, 255, 0.5)",
    grayLighterOpacity: "rgba(255, 255, 255, 0.1)",
    orange: "#ff8200",
    pink: "#ff02dc",
    lightGrey: "#848E9C",
    lightpurple: "#F2EEF6",
  },
};

export const defaultTheme = createTheme({
  palette,
  typography: {
    fontFamily: "'Lato', sans-serif;",
    allVariants: { fontFamily: "Lato", color: palette.common.black },
    h1: {
      fontSize: 32,
      fontWeight: 600,
      fontFamily: "Lato",
    },
    h2: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "Lato",
    },
    h3: {
      fontSize: 15,
      fontWeight: 700,
      fontFamily: "Lato",
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Lato",
      color: palette.common.lightGrey,
    },
    h5: {
      fontSize: 16,
      fontWeight: 300,
      fontFamily: "Lato",
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Lato",
      lineHeight: "inherit",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Lato",
      color: palette.common.lightGrey,
    },
    caption: {
      fontSize: 14,
      fontFamily: "Lato",
      letterSpacing: "normal",
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Lato",
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Lato",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Lato'",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Lato",
          fontSize: 15,
          fontWeight: 600,
          borderRadius: "8px",
          textTransform: "none",
        },
        contained: {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          "&:hover": {
            boxShadow: "0 0 10px 3px rgba(255, 100, 169, 0.7)",
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          minWidth: "100px",
        },
        select: {
          color: palette.common.lightGrey,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          "&::placeholder": {
            color: "black",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
          fontWeight: 600,
          fontSize: 10,
          height: 20,
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiInputBase-root": {
              borderRadius: "8px",
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "& .MuiFilledInput-root": {
              borderRadius: "8px",
            },
          },
        },
      ],
    },

    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       background: palette.secondary.main,
    //     },
    //   },
    // },
  },
  // components: {
  //   MuiAppBar: {
  //     styleOverrides: {
  //       root: {
  //         background: palette.secondary.main,
  //         borderRadius: 0,
  //         padding: "12px 0",
  //       },
  //     },
  //   },
  //   MuiChip: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: 4,
  //       },
  //       filled: {
  //         background: palette.primary.light,
  //         color: palette.common.white,
  //       },
  //       outlined: {
  //         color: palette.primary.light,
  //         borderColor: palette.primary.light,
  //         fontWeight: 600,
  //       },
  //     },
  //   },
  //   MuiLinearProgress: {
  //     styleOverrides: {
  //       bar1Determinate: {
  //         background: palette.gradients.dark,
  //         borderRadius: 40,
  //       },
  //       root: {
  //         background: palette.info.lightest,
  //         height: 6,
  //         borderRadius: 40,
  //       },
  //     },
  //   },
  //   MuiPaper: {
  //     styleOverrides: {
  //       elevation1: {
  //         border: "1px solid #F2F2F3",
  //         boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  //       },
  //       root: {
  //         borderRadius: 8,
  //       },
  //     },
  //   },
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         fontFamily: "Inter",
  //         fontSize: 16,
  //       },
  //       contained: {
  //         fontSize: 16,
  //         fontWeight: 600,
  //       },
  //     },
  //   },
  //   MuiSelect: {
  //     variants: [
  //       {
  //         props: { variant: "outlined" },
  //         style: {
  //           "&.MuiInputBase-root": {
  //             background: "#F8F8F8",
  //             border: "1px solid #F3F3F2",
  //             borderRadius: "8px",
  //           },
  //           "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //             border: "2px solid",
  //             borderColor: "#C72A8E !important",
  //             boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
  //             borderRadius: "8px",
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   MuiTextField: {
  //     variants: [
  //       {
  //         props: { variant: "outlined" },
  //         style: {
  //           "& .MuiInputBase-root": {
  //             background: "#F8F8F8",
  //             border: "1px solid #F3F3F2",
  //             borderRadius: "8px",
  //           },
  //           "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
  //             border: "2px solid",
  //             borderColor: "#C72A8E !important",
  //             boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
  //             borderRadius: "8px",
  //           },
  //         },
  //       },
  //       {
  //         props: { variant: "filled" },
  //         style: {
  //           "& .MuiFilledInput-root.Mui-focused": {
  //             border: "2px solid",
  //             borderColor: "#C72A8E !important",
  //             boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
  //           },
  //           "& .MuiInputLabel-root.Mui-focused": {
  //             background:
  //               "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
  //             WebkitBackgroundClip: "text",
  //             WebkitTextFillColor: "transparent",
  //           },
  //           "& .MuiFormHelperText-root": {
  //             backgroundColor: "#F3F3F2",
  //             borderRadius: 8,
  //             padding: 12,
  //             margin: 0,
  //             marginTop: 10,
  //           },

  //           "& .MuiFormLabel-root": {
  //             fontSize: "14px",
  //             lineHeight: "2em",
  //             fontFamily: "Inter",
  //             color: "#7F7D85",
  //             fontWeight: 500,
  //           },
  //           "& .MuiFilledInput-root:before": {
  //             display: "none",
  //           },
  //           "& .MuiFilledInput-root:after": {
  //             display: "none",
  //           },
  //           "& .MuiFilledInput-root": {
  //             fontWeight: 500,
  //             border: "2px solid #F3F3F2",
  //             overflow: "hidden",
  //             borderRadius: 16,
  //             borderColor: "#F3F3F2",
  //             backgroundColor: "#FFFFFF",
  //             // transition: theme.transitions.create([
  //             //   "border-color",
  //             //   "background-color",
  //             //   "box-shadow",
  //             // ]),
  //             "&:hover": {
  //               backgroundColor: "#FFFFFF",
  //             },
  //           },
  //         },
  //       },
  //     ],
  //   },

  //   MuiFormHelperText: {
  //     styleOverrides: {
  //       root: {
  //         fontFamily: "Roboto",
  //         fontSize: 12,
  //       },
  //     },
  //   },
  // },
});
