import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { defaultTheme } from "../theme";

import RouterBreadcrumbs from "./Breadcrumbs";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useGetUserSelf } from "../query/user";
import { getInitialsFromName } from "./MuiTable/utils";

import { EXTERNAL_PATHS, logOut, redirectToExternal } from "./utils";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { data: user } = useGetUserSelf();

  const name =
    user?.first_name && user?.last_name
      ? `${user.first_name} ${user.last_name}`
      : user?.name;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          background: defaultTheme.palette.background.default,
          justifyContent: "space-between",
          flexDirection: "row",
          px: 3,
          height: 100,
          alignItems: "center",
          boxShadow: "none",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box gap={1} pl={3}>
          <RouterBreadcrumbs />
          <Typography variant="h2" mt={1}>
            CRM
          </Typography>
        </Box>
        <Toolbar sx={{ gap: 1 }}>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>

          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            color="inherit"
          >
            My Account
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={(e) => redirectToExternal(EXTERNAL_PATHS.OWN_ACCOUNT, e)}
            >
              My account
            </MenuItem>
            <MenuItem onClick={(e) => logOut(e)}>Logout</MenuItem>
          </Menu>
          <Box>
            {user ? (
              <Avatar alt={getInitialsFromName(name ? name : "NN")}>
                {getInitialsFromName(name ? name : "NN")}
              </Avatar>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
