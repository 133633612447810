import Stack from "@mui/material/Stack";
import ModalLayout from "./ModalLayout";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

type WarningModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => void;
  headerText: string;
  contentText: string;
};

const WarningModal = ({
  open,
  setOpen,
  action,
  headerText,
  contentText,
}: WarningModalProps) => {
  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <Stack
        direction="column"
        spacing={4}
        sx={{
          alignItems: "center",
          pt: 4,
        }}
      >
        <WarningAmberIcon
          sx={{
            color: "red",
            fontSize: "4rem",
          }}
        />
        <Stack direction="column" spacing={2} alignItems={"center"}>
          <Typography variant="h2">{headerText}</Typography>
          <Typography variant="h3">{contentText}</Typography>
        </Stack>

        <Box display={"flex"} justifyContent={"flex-end"} pt={2} pb={2} gap={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpen(!open)}
          >
            Cancel{" "}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "red" }}
            onClick={(e) => {
              e.preventDefault();
              action();
            }}
            size="small"
            type="submit"
          >
            Confirm
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};

export default WarningModal;
