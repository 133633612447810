import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Controller, FieldError, Control } from "react-hook-form";

type HookedFormFieldPropsType = {
  name: string;
  control: Control<any>;
  label: string;
  helperText: string;
  error?: FieldError;
  required?: boolean;
  [x: string]: any;
};

const HookedFormTextField = ({
  control,
  error,
  name,
  label,
  helperText,
  required,
  ...rest
}: HookedFormFieldPropsType) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={error ? true : false}
            id={name}
            required={required}
            label={label}
            helperText={error ? error?.message : helperText}
            {...rest}
          />
        )}
      />
    </Box>
  );
};

export default HookedFormTextField;
