import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { useParties } from "../query/search";
import { useMemo } from "react";
import { Person, PersonWidgetProps } from "../types/Person";

type PeopleListWidgetProps = {
  persons: Person[];
  setSelectedPersonId: (id: string) => void;
  selectedPersonId: string;
  sentryRef: any;
  loading: boolean;
  hasNextPage: boolean;
  handleSelectPersonForList: (
    e: React.ChangeEvent<HTMLInputElement>,
    personId: string,
  ) => void;
};

type PersonWidgetCardProps = {
  person: PersonWidgetProps;
  setSelectedPersonId: (id: string) => void;
  selectedPersonId: string;
  handleSelect: (
    e: React.ChangeEvent<HTMLInputElement>,
    personId: string,
  ) => void;
};

const PeopleListWidgetCard = ({
  person,
  setSelectedPersonId,
  selectedPersonId,
  handleSelect,
}: PersonWidgetCardProps) => {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      spacing={1}
      py={2}
      borderBottom={1}
      borderColor="divider"
      key={person.id}
      onClick={() => setSelectedPersonId(person.id)}
      width="100%"
      sx={{
        border: selectedPersonId === person.id ? "2px solid #ffb1d4" : "none",
        borderRadius: selectedPersonId === person.id ? "8px" : "none",
        ":hover": {
          border: "2px solid",
          borderColor: "#FF64A9 !important",
          boxShadow: "0px 0px 10px #FF64A9 !important",
          borderRadius: "8px",
        },
      }}
    >
      <Checkbox onChange={(e) => handleSelect(e, person.id)} />
      <Stack direction="row" alignItems={"center"} spacing={1}>
        <Avatar src={person?.img_src} />
        <Stack direction={"column"}>
          <Box display={"inline-flex"} gap={0.3}>
            {person?.org?.img ? (
              <Box
                component="img"
                src={person?.org?.img}
                sx={{
                  width: "20px",
                  height: "16px",
                }}
              ></Box>
            ) : null}

            <Typography sx={{ fontSize: "11px", fontWeight: 500 }}>
              {person?.org?.name}
            </Typography>
          </Box>
          <Typography variant="h3">{person?.name}</Typography>
          <Typography sx={{ fontSize: "10px", fontWeight: 400 }}>
            {person?.current_roles_titles}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PeopleListWidget = ({
  persons,
  setSelectedPersonId,
  selectedPersonId,
  sentryRef,
  loading,
  hasNextPage,
  handleSelectPersonForList,
}: PeopleListWidgetProps) => {
  const { data: parties } = useParties();

  const personsWithOrg = useMemo<PersonWidgetProps[]>(() => {
    return persons.map((person) => {
      return {
        ...person,
        org: parties?.find((party) => party.id === person.party),
      };
    });
  }, [persons, parties]);

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        py: 2,
        borderRadius: "8px",
        minWidth: "326px",
      }}
    >
      {personsWithOrg && personsWithOrg.length ? (
        <>
          <Box
            pb={2}
            display={"flex"}
            alignItems={"center"}
            px={2}
            borderBottom={1}
            borderColor="divider"
          >
            <Typography variant="h3">Filter People</Typography>
          </Box>

          {personsWithOrg.map((person: PersonWidgetProps) => (
            <PeopleListWidgetCard
              person={person}
              selectedPersonId={selectedPersonId}
              setSelectedPersonId={setSelectedPersonId}
              handleSelect={handleSelectPersonForList}
            />
          ))}
          {(loading || hasNextPage) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
              ref={sentryRef}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      ) : null}
    </Box>
  );
};

export default PeopleListWidget;
