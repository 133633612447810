import { useState } from "react";
import { useEmails } from "../../query/emails";
import { MRT_PaginationState } from "material-react-table";
import { EmailSearchValues, EmailType } from "../../types/Email";

import EmailsTable from "./EmailsTable";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button, MenuItem } from "@mui/material";
import { PaginatedResponse } from "../../types/utils";
import { PaginationState } from "@tanstack/react-table";
import { useForm } from "react-hook-form";
import HookedFormSelectField from "../../components/HookedSelectField";
import { useGetUsers } from "../../query/user";
import { useGetColumns } from "./hooks";

type EmailsContainerProps = {
  status: string;
};

const EmailsContainer = ({ status }: EmailsContainerProps) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const [searchFull, setSearchFull] = useState<EmailSearchValues>({ status });

  const { control, getValues } = useForm({
    defaultValues: {
      q: "",
      sent_by: "",
      status: status,
    },
  });
  const { data: emails, isLoading } = useEmails(
    pagination.pageIndex,
    pagination.pageSize,
    searchFull,
  );

  const handleSubmit = () => {
    setSearchFull({ ...getValues(), status });
  };
  return (
    <>
      {emails ? (
        <EmailsTab
          emails={emails}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          control={control}
          handleSubmit={handleSubmit}
          status={status}
        />
      ) : (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

type EmailsTabProps = {
  emails: PaginatedResponse<EmailType>;
  isLoading: boolean;
  pagination: PaginationState;
  setPagination: any;
  control: any;
  status: string;
  handleSubmit: () => void;
};
const EmailsTab = (props: EmailsTabProps) => {
  const { data: users } = useGetUsers();
  const columns = useGetColumns(props.status, props.isLoading);
  return (
    <Box>
      <Box py={2} display="inline-flex">
        <Box gap={1} display="inline-flex">
          <HookedFormSelectField
            control={props.control}
            label="Search for content"
            name="q"
            size="small"
            variant="outlined"
            sx={{
              flex: 1,
              minWidth: "170px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {props.status === "sent" ? (
            <HookedFormSelectField
              control={props.control}
              label="Sent by:"
              name="sent_by"
              select
              size="small"
              sx={{
                minWidth: "200px",
              }}
            >
              <MenuItem value="" key="none-value">
                Clear
              </MenuItem>
              {users?.results.map((user) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.name} {user.email}
                </MenuItem>
              ))}
            </HookedFormSelectField>
          ) : null}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleSubmit}
          size="small"
          sx={{ marginX: 2 }}
        >
          Search
        </Button>
      </Box>
      <EmailsTable
        columns={columns}
        data={props.emails.results}
        totalCount={props.emails.total_count}
        setPagination={props.setPagination}
        pagination={props.pagination}
        isFetching={props.isLoading}
      />
    </Box>
  );
};

export default EmailsContainer;
