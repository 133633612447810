import Box from "@mui/material/Box";

import { useState } from "react";

import Stack from "@mui/material/Stack";
import { Button, CircularProgress, Typography } from "@mui/material";

import { useListsAll } from "../../query/list";

import CreateListModal from "../../components/CreateListModal";
import ListsTable from "../../components/ListsTable";

const ListsTableContainer = () => {
  const {
    data: lists,
    isLoading: listsLoading,
    isFetching: listsFetching,
  } = useListsAll();
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);

  return (
    <Box>
      {listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "60%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}

      {lists && lists.results.length ? (
        <ListsTable lists={lists.results} isFetching={listsFetching} />
      ) : null}
      {!lists?.results.length && !listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box textAlign={"center"} sx={{ top: "50%" }}>
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <Typography variant="h2">Create new lists</Typography>
              <Typography variant="h3">
                Create new lists to organise your contacts
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setListsModalOpen(true)}
                sx={{ maxWidth: 180 }}
                size="small"
              >
                Create new list
              </Button>
            </Stack>
          </Box>
        </Box>
      ) : null}

      <CreateListModal
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />
    </Box>
  );
};

export default ListsTableContainer;
