import { API_PATHS } from "../constants";
import { User } from "../types/User";
import { PaginatedResponse } from "../types/utils";
import { HttpMethod, secureRequest } from "./ApiService";

export const getUser = async (id: string): Promise<User> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.USERS + `/${id}`,
    {},
    true,
  );
};

export const getUserSelf = async (): Promise<User> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.USERS + `/self`,
    {},
    true,
  );
};

export const getUsers = async (): Promise<PaginatedResponse<User>> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.USERS, {});
};
