import {
  Avatar,
  Container,
  InputAdornment,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableHeadCellFilterContainer,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { Person } from "../../types/Person";
import SearchIcon from "@mui/icons-material/Search";

import ListsBadgesWidget from "../../components/MuiTable/ListsBadgesWidget";
import { useListsAll } from "../../query/list";
import PersonBioDetail from "../../components/MuiTable/PersonBioDetail";
import { getOrgNameFromKey } from "../Persons/utils";
import { useNavigate } from "react-router-dom";

type PersonsTableProps = {
  persons: Person[];
  setSelected: (selected: string[]) => void;
  selected: string[];
};

const PersonsTable = ({
  persons,
  setSelected,
  selected,
}: PersonsTableProps) => {
  const { data: lists } = useListsAll();
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const getCellWidth = (drawerOpen: boolean | undefined, matches: boolean) => {
    if (matches && !drawerOpen) {
      return 200;
    } else if (matches && drawerOpen) {
      return 150;
    } else if (!matches && !drawerOpen) {
      return 80;
    } else if (drawerOpen) {
      return 40;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Profile",
        Cell: ({ row, renderedCellValue }) => (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Avatar src={row.original.img_src} />
            {renderedCellValue}
          </Stack>
        ),
        filterFn: "includesString",
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          placeholder: "Search",
          name: "search",
          size: "small",
          variant: "outlined",
          sx: {
            flex: 1,
            minWidth: "180px",
          },
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        },
      },
      {
        accessorKey: "organization",
        header: "Organization",
        size: getCellWidth(false, matches),
        accessorFn: (row) =>
          row.organization ? getOrgNameFromKey(row.organization) : "N/A",
        filterVariant: "multi-select",
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "organization",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length
                ? `${selected.length} selected`
                : "Organizations";
            },
          },
        },
      },
      {
        accessorKey: "region",
        accessorFn: (row) => (row.region ? row.region : "N/A"),
        header: "Region",
        filterVariant: "multi-select",
        size: getCellWidth(false, matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "region",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length
                ? `${selected.length} selected`
                : "Regions";
            },
          },
        },
      },
      {
        header: "Party",
        accessorKey: "party",
        size: getCellWidth(false, matches),
        filterVariant: "multi-select",
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "party",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length
                ? `${selected.length} selected`
                : "Parties";
            },
          },
        },
      },
      {
        accessorKey: "constituency",
        header: "Constituency",
        size: getCellWidth(false, matches),

        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "constituency",
          size: "small",
          variant: "outlined",
          placeholder: "Constituency",
        },
      },
      {
        //TODO: add color coding via renderColumnFilterModeMenuItems
        filterVariant: "multi-select",
        accessorKey: "lists",
        header: "Lists",
        size: getCellWidth(false, matches),
        accessorFn: (row) => {
          const filtered = lists?.results.filter((list) =>
            list.person_ids?.includes(row.id),
          );
          return filtered?.map((list) => list.list_name);
        },
        filterSelectOptions: lists?.results.map((list) => list.list_name),
        filterFn: (row, id, filterValue) => {
          if (!filterValue.length) return true;
          return filterValue.some((value: string) =>
            (row.getValue(id) as string[]).includes(value),
          );
        },
        Cell: ({ row }) => <ListsBadgesWidget personId={row.original?.id} />,
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "lists",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Lists";
            },
          },
        },
      },
    ],
    [lists, matches],
  );
  const table = useMaterialReactTable({
    columns,
    data: persons,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    enableFacetedValues: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    selectAllMode: "all",
    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/people/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
      },
    },
    enableExpandAll: false,
    renderDetailPanel: ({ row }) =>
      row.original.id ? <PersonBioDetail personId={row.original.id} /> : null,
  });

  useEffect(() => {
    if (rowSelection && setSelected) {
      setSelected(
        table.getSelectedRowModel().rows.map((row) => row.original.id),
      );
    }
  }, [persons, setSelected, rowSelection, table]);

  useEffect(() => {
    if (selected.length === 0) {
      setRowSelection({});
    }
  }, [selected]);

  return (
    <Container maxWidth="xl">
      <Stack direction={"row"} py={3} gap={2.5} justifyContent={"flex-end"}>
        {table.getLeafHeaders().map((header) => {
          return [
            "constituency",
            "party",
            "region",
            "lists",
            "name",
            "organization",
          ].includes(header.id) ? (
            <MRT_TableHeadCellFilterContainer
              key={header.id}
              header={header}
              table={table}
              in
            />
          ) : null;
        })}
      </Stack>

      <MaterialReactTable table={table} />
    </Container>
  );
};
export default PersonsTable;
