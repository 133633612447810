import { Note, NoteInput, NoteInputBulk } from "../types/Notes";
import { HttpMethod, secureRequest } from "./ApiService";
import { API_PATHS } from "../constants";

export const createNote = async (data: NoteInput): Promise<Note> => {
  return await secureRequest(HttpMethod.Post, API_PATHS.NOTES, { data });
};

export const createNotesBulk = async (data: NoteInputBulk): Promise<string> => {
  const url = API_PATHS.NOTES + "/batch";
  return await secureRequest(HttpMethod.Post, url, { data });
};

type UpdateNoteInput = {
  note_id: string;
  data: NoteInput;
};

export const updateNote = async ({
  note_id,
  data,
}: UpdateNoteInput): Promise<Note> => {
  return await secureRequest(
    HttpMethod.Patch,
    `${API_PATHS.NOTES}/${note_id}`,
    { data },
  );
};

export const deleteNote = async ({
  note_id,
}: {
  note_id: string;
}): Promise<Note> => {
  return await secureRequest(
    HttpMethod.Delete,
    `${API_PATHS.NOTES}/${note_id}`,
    {},
  );
};

export const getNote = async (noteId: string): Promise<Note> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.NOTES + "/" + noteId,
    {},
  );
};
