import { HttpMethod, secureFileDownload, secureRequest } from "./ApiService";
import { API_PATHS } from "../constants";
import { Attachment } from "../types/Attachment";

export const uploadFile = async (file: File): Promise<Attachment> => {
  const formData = new FormData();
  formData.append("file", file);
  return await secureRequest(HttpMethod.Post, API_PATHS.ATTACHMENTS, {
    data: formData,
  });
};

export const getAttachment = async (
  attachmentId: string,
): Promise<Attachment> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.ATTACHMENTS + "/" + attachmentId,
    {},
    true,
  );
};

export const downloadAttachment = async (
  attachmentId: string,
): Promise<Response> => {
  const url: string = `${API_PATHS.ATTACHMENTS}/${attachmentId}/download`;
  return await secureFileDownload(HttpMethod.Get, url, {});
};
