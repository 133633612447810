import {
  useQuery,
  keepPreviousData,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";
import { LISTS } from "./constants";
import {
  createList,
  getList,
  getLists,
  getListsAll,
  updateList,
} from "../services/ListsService";
import { NotificationService } from "../services/NotificationService";
import { useEffect, useState } from "react";
import { PaginatedResponse } from "../types/utils";
import { ProfileList } from "../types/List";

export function useLists(
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
) {
  return useQuery({
    queryKey: [LISTS, offset, limit, order],
    queryFn: () => getLists(offset, limit, order),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useListsAll() {
  return useQuery({
    queryKey: [LISTS],
    queryFn: () => getListsAll(),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export const useListsForPerson = (personId: string) => {
  const { data: lists, isLoading } = useListsAll();
  const [filteredLists, setFilteredLists] =
    useState<PaginatedResponse<ProfileList>>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (lists) {
      setLoading(true);
      const filtered = lists.results.filter((list) =>
        list.person_ids ? list.person_ids.includes(personId) : false,
      );
      setFilteredLists({ ...lists, results: filtered });
      setLoading(false);
    }
  }, [lists, personId, setFilteredLists]);
  return { data: filteredLists, isLoading: loading || isLoading };
};

export const useCreateListMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LISTS] });
      NotificationService.Success("List created!");
    },
  });
};

// export const useAddPersonsToListMutation = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: addPersonsToList,
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: [PERSONS] });
//     },
//   });
// };

// export const useRemovePersonsFromListMutation = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: removePersonsFromList,
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: [PERSONS] });
//     },
//   });
// };

export const useUpdateListMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LISTS] });
      NotificationService.Success("List updated!");
    },
  });
};

export function useGetList(listId: string) {
  return useQuery({
    queryKey: [LISTS, listId],
    queryFn: () => getList(listId),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}
