export type EmailType = {
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  subject: string;
  content: string;
  from_field?: string;
  replyTo?: string[];
  org_id: string;
  files?: string[];
  sentDraft?: string;
  id: string;
  // sent: string;
  recipients?: string[];
  sent_by?: string;
  status?: string;
};

export type EmailInputType = {
  subject?: string;
  content?: string;
  from_field?: string;
  replyTo?: string[];
  files?: string[];
  recipients?: string[];
};

export type CrmSentEmail = {
  id: string;
  email_id: string;
  content: string;
  subject: string;
  org_id: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  attachments?: string[];
  from_field?: string;
  People_UID: string;
  replyTo: string[];
  status?: string;
};

export type SendgridEvent = {
  email: string;
  timestamp: number;
  event: string;
  category: string[];
  sg_event_id: string;
  email_id: string;
  org_id: string;
};

export type EmailSearchValues = {
  status?: string;
  q?: string;
  sent_by?: string;
};

export enum SentEmailStatusEnum {
  sending = "Sending",
  processed = "Processed",
  deferred = "Deferred",
  delivered = "Delivered",
  bounce = "Bounced",
  dropped = "Dropped",
  spamreport = "Moved to spam",
  open = "Opened",
  click = "Clicked",
}
export let sentEmailStatusEnumKeys: keyof typeof SentEmailStatusEnum;

export type EmailStatusType = {
  delivered: number;
  opened: number;
};
