export const PERSONS = "PERSONS";
export const PERSON_INFO = "PERSON_INFO";
export const LISTS = "LISTS";
export const REGIONS = "REGIONS";
export const PARTIES = "PARTIES";
export const CONTENT_TYPES = "CONTENT_TYPES";
export const SEARCH_CONTENT = "SEARCH_CONTENT";
export const SEARCH_PERSON = "SEARCH_PERSON";
export const APPGS = "APPGS";
export const COMMITTEES = "COMMITTEES";
export const PERSON_STAFF = "PERSON_STAFF";
export const PERSON_NOTES = "PERSON_NOTES";
export const PERSON_GEO = "PERSON_GEO";
export const PERSON_PARLYDATA = "PERSON_PARLYDATA";
export const PERSON_PARLYBIO = "PERSON_PARLYBIO";
export const ATTACHMENTS = "ATTACHMENTS";
export const NOTES = "NOTES";
export const USERS = "USERS";
export const USERS_SELF = "USERS_SELF";
export const CONTACTS = "CONTACTS";
export const EMAILS = "EMAILS";
export const SENT_EMAILS = "SENT_EMAILS";
export const SENT_EMAILS_STATS = "SENT_EMAILS_STATS";
export const PERSONS_ELECTED = "PERSONS_ELECTED";
export const EMAIL_STATUS = "EMAIL_STATUS";
