import { exportPersonsData } from "./PersonsService";

export const getBasePath = () => {
  const { protocol, hostname, origin } = window.location;
  const urlPath =
    hostname === "localhost" ? `${protocol}//${hostname}:8000` : origin;
  return urlPath + "/api";
};

export const truncateText = (
  text: string | undefined,
  length: number = 120,
) => {
  if (text)
    return text.length > length ? text.substring(0, length) + "..." : text;
  return "";
};

export const formatDate = (date: string | undefined) => {
  if (date)
    return new Date(date).toLocaleDateString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  return "";
};

export const getUrlFromFilters = <T>(filters: T): string => {
  const searchParams = new URLSearchParams();
  Object.entries(filters as Object).forEach(([key, value]) => {
    if (value === undefined) return;
    if (value === "") return;
    if (Array.isArray(value) && value.length === 0) return;
    if (Array.isArray(value)) {
      value.forEach((v) => searchParams.append(key, v));
    } else if ((value as any) instanceof Date) {
      searchParams.set(key, (value as any).toISOString());
    } else {
      searchParams.set(key, String(value));
    }
  });
  return searchParams.toString();
};

export const handlePersonsExport = async (
  e: React.MouseEvent<HTMLButtonElement>,
  personIds: string[],
) => {
  e.preventDefault();
  const attachmentFile = await exportPersonsData(personIds);
  const blob = await attachmentFile.blob();
  const newBlob = new Blob([blob]);
  const url = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `export.csv`);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};
