import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { Controller } from "react-hook-form";

type HookedMultiSelectProps = {
  fieldName: string;
  control: any;
  data?: any[];
  labelName: string;
  selection: any;
  itemLabelName: string;
  valueField?: string;
  [x: string]: any;
};

const HookedMultiSelect = (props: HookedMultiSelectProps) => {
  const {
    fieldName,
    control,
    data,
    labelName,
    selection,
    itemLabelName,
    valueField = "id",
    ...rest
  } = props;

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <FormControl size="small" {...rest}>
          <InputLabel>{labelName}</InputLabel>
          <Select
            {...field}
            label={fieldName.toUpperCase()}
            multiple
            renderValue={(selected) => {
              return `${selected.length} selected`;
            }}
          >
            {data?.map((dataItem) => (
              <MenuItem key={dataItem.id} value={dataItem[valueField]}>
                <Checkbox
                  checked={
                    selection &&
                    Boolean(
                      selection.findIndex((x: any) => {
                        if (x[valueField] && dataItem[valueField])
                          return x[valueField] === dataItem[valueField];
                        return dataItem[valueField] === x;
                      }) > -1,
                    )
                  }
                />

                {dataItem[itemLabelName]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
export default HookedMultiSelect;
