import { Button, Divider, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PersonDetail } from "../../../types/Person";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import EditSalutationModal from "../../../components/EditSalutationModal";
import { useState } from "react";
import { useGetPersonInfo, useGetPersonStaff } from "../../../query/person";
import PersonNoteModal from "../../../components/AddPersonNote";
import GridCell from "./GridCell";
import StaffCell from "./StaffCell";
import { getOrgNameFromKey } from "../utils";

type PersonOverviewProps = {
  person: PersonDetail;
};

const PersonOverview = ({ person }: PersonOverviewProps) => {
  const [salutationModalOpen, setSalutationModalOpen] =
    useState<boolean>(false);
  const [personNoteModalOpen, setPersonNoteModalOpen] =
    useState<boolean>(false);
  const { data: personInfo } = useGetPersonInfo(person.id);
  const { data: personStaff } = useGetPersonStaff(person.id);
  const isContact = person?.organization
    ? getOrgNameFromKey(person?.organization) === "N/A"
    : false;
  return (
    <>
      <Stack direction="column">
        <Grid container spacing={4}>
          <Grid item xs={10}>
            <Typography variant="h2">Person Details</Typography>
          </Grid>
          <GridCell
            title="First Name"
            content={person.first_name}
            cellSize={4}
          />
          <GridCell title="Last Name" content={person.surname} cellSize={4} />
          <GridCell
            title="Email Address"
            content={person.email_address}
            cellSize={4}
          />
          <GridCell
            title="Position"
            content={
              person?.current_roles_titles
                ? person?.current_roles_titles.split("\n")
                : "Backbencher"
            }
            cellSize={4}
          />
          {isContact ? (
            <GridCell
              title="Organization"
              content={person?.organization}
              cellSize={4}
            />
          ) : (
            <GridCell
              title="Department"
              content={person?.department}
              cellSize={4}
            />
          )}

          {!isContact ? (
            <GridCell title="Party" content={person?.party} cellSize={4} />
          ) : null}
          {!isContact ? (
            <GridCell
              title="Constituency"
              content={person?.constituency}
              cellSize={4}
            />
          ) : null}

          {!isContact ? (
            <GridCell title="Website" content={person?.website} cellSize={4} />
          ) : null}

          {!isContact ? (
            <GridCell
              title="Ministerial Email address"
              content={person.ministerial_email_address}
              cellSize={4}
            />
          ) : null}

          {!isContact ? (
            <GridCell
              title="Parliamentary Phone No."
              content={person.parliamentary_phone_number}
              cellSize={4}
            />
          ) : (
            <GridCell
              title="Office Phone No."
              content={person.office_phone_number}
              cellSize={4}
            />
          )}
          {person.twitter ? (
            <Grid item xs={6}>
              <Typography variant="h4">Twitter</Typography>

              <Button
                variant="text"
                size="small"
                endIcon={<ArrowOutwardIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `https://www.twitter.com/${person.twitter}`,
                    "_blank",
                  );
                }}
              >
                {person.twitter}
              </Button>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {personStaff && personStaff.length ? (
            <StaffCell staff={personStaff} />
          ) : null}

          <Grid item xs={12}>
            <Typography variant="h2">Salutation</Typography>
            <Button
              variant="text"
              size="small"
              endIcon={<ArrowOutwardIcon />}
              onClick={(e) => {
                e.preventDefault();
                setSalutationModalOpen(true);
              }}
            >
              {personInfo?.salutation || person?.salutation}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" py={2}>
              Customer Note
            </Typography>
            <Typography variant="h3">{personInfo?.text_note}</Typography>

            <Button
              variant="text"
              size="small"
              endIcon={<ArrowOutwardIcon />}
              onClick={(e) => {
                e.preventDefault();
                setPersonNoteModalOpen(true);
              }}
            >
              {personInfo?.text_note ? "Edit" : "Add"}
            </Button>
          </Grid>

          {person?.bio_summary ? (
            <GridCell
              title="Bio"
              content={person?.bio_summary}
              cellSize={10}
              bold
            />
          ) : null}

          {person?.biog_2_early_life ? (
            <GridCell
              title="Early Life"
              content={person?.biog_2_early_life.split("\n")}
              cellSize={10}
              bold
            />
          ) : null}
          {person?.biog_3_professional_career ? (
            <GridCell
              title="Professional Career"
              content={person?.biog_3_professional_career.split("\n")}
              cellSize={10}
              bold
            />
          ) : null}

          {person?.biog_4_political_career ? (
            <GridCell
              title="Political Career"
              content={person?.biog_4_political_career.split("\n")}
              cellSize={10}
              bold
            />
          ) : null}
          {person?.biog_5_personal_life ? (
            <GridCell
              title="Personal Life"
              content={person?.biog_5_personal_life.split("\n")}
              cellSize={10}
              bold
            />
          ) : null}
          {person?.biog_6_controversies ? (
            <GridCell
              title="Controversies"
              content={person?.biog_6_controversies.split("\n")}
              cellSize={10}
              bold
            />
          ) : null}
        </Grid>
      </Stack>
      <EditSalutationModal
        open={salutationModalOpen}
        setOpen={setSalutationModalOpen}
        person={person}
        personInfo={personInfo}
      />
      <PersonNoteModal
        open={personNoteModalOpen}
        setOpen={setPersonNoteModalOpen}
        personInfo={personInfo}
        personId={person.id}
      />
    </>
  );
};

export default PersonOverview;
