import Box from "@mui/material/Box";
import { Person } from "../../../types/Person";
import {
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo, useState } from "react";
import { useGetPersonNotes } from "../../../query/person";
import { Note } from "../../../types/Notes";

import UserCell from "./UserCell";

import AttachmentLinkWidget from "../../../components/AttachmentsLinkWidget";
import {
  NoteCreateModalContainer,
  NoteUpdateModalContainer,
} from "../../Engagements/NotesModals";
import WarningModal from "../../../components/WarningModal";
import { useDeleteNoteMutation } from "../../../query/note";

type EngagementsContainerProps = {
  person: Person;
};

const EngagementsContainer = ({ person }: EngagementsContainerProps) => {
  const { data, isLoading } = useGetPersonNotes(person.id);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Typography variant="h2">Previous Engagements</Typography>
          <Button
            variant="contained"
            size="small"
            onClick={() => setModalOpen(!modalOpen)}
            startIcon={<AddIcon />}
          >
            Add engagement
          </Button>
        </Stack>
        {data ? (
          <Engagements
            notes={data}
            isLoading={isLoading}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            personId={person.id}
          />
        ) : null}
        {isLoading ? (
          <Box display="flex" justifyContent={"center"}>
            <Box position="absolute" sx={{ top: "50%" }}>
              <Stack direction={"column"} alignItems={"center"}>
                <CircularProgress />
              </Stack>
            </Box>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

type EngagementsProps = {
  notes: Note[];
  isLoading: boolean;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  personId: string;
};

const Engagements = ({
  notes,
  modalOpen,
  isLoading,
  setModalOpen,
  personId,
}: EngagementsProps) => {
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { mutate: deleteNote } = useDeleteNoteMutation();
  const columns = useMemo<MRT_ColumnDef<Note>[]>(
    () => [
      {
        accessorKey: "title",
        header: "Engagement title",
      },
      {
        accessorFn: (row) => {
          const date = row.date || row.created_at;
          return date ? new Date(date).toLocaleDateString("en-GB") : "";
        },
        header: "Date created",
      },
      {
        accessorKey: "note_type",
        header: "Engagement type",
      },
      {
        accessorFn: (row) => {
          if (row.logged_by) {
            return row.logged_by;
          } else if (row.created_by) {
            return <UserCell userId={row.created_by} />;
          }
          return "Unknown";
        },
        header: "Logged by",
      },
      {
        header: "Attachment(s)",
        accessorKey: "attachments",
        // accessorFn: (row): string[] => {
        //   return getAttachments(row.attachments);
        // },
        Cell: ({ cell, row }) => {
          const attachments = row.original.attachments;
          if (!attachments) {
            return null;
          }
          return (cell.getValue() as string[]).map((attachment) => {
            if (attachment.includes("/")) {
              return (
                <AttachmentLinkWidget attachmentId={attachment.split("/")[0]} />
              );
            }
            return <AttachmentLinkWidget attachmentId={attachment} />;
          });
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: notes,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableRowActions: true,
    initialState: {
      showGlobalFilter: true,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading,
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key="edit"
        onClick={() => {
          setSelectedNote(row.original);
          closeMenu();
          setEditModalOpen(!editModalOpen);
        }}
      >
        View/Edit
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => {
          setSelectedNote(row.original);
          closeMenu();
          setDeleteModalOpen(!deleteModalOpen);
        }}
      >
        Delete
      </MenuItem>,
    ],
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {modalOpen ? (
        <NoteCreateModalContainer
          open={modalOpen}
          setOpen={setModalOpen}
          personId={personId}
        />
      ) : null}
      {selectedNote && editModalOpen ? (
        <NoteUpdateModalContainer
          open={editModalOpen}
          setOpen={setEditModalOpen}
          noteId={selectedNote.id}
        />
      ) : null}
      {deleteModalOpen && selectedNote ? (
        <WarningModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          action={() => {
            deleteNote({ note_id: selectedNote.id });
            setDeleteModalOpen(!deleteModalOpen);
            setSelectedNote(null);
          }}
          headerText={"Are you sure you want to delete this note?"}
          contentText={"This action cannot be undone."}
        />
      ) : null}
    </>
  );
};

export default EngagementsContainer;
