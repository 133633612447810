import {
  Box,
  Divider,
  InputAdornment,
  List,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CollapsableRow from "../../components/CollapsableRow";
import { useMemo, useState } from "react";
import { getOrgNameFromKey } from "./utils";
import { OrganisationsFilterItem } from "./index";

interface Entity {
  type: string;
  name: string;
  id: string;
  parties?: EntityGroup;
  committees?: EntityGroup;
  cross_party_groups?: EntityGroup;
  opened?: boolean;
}

interface EntityGroup {
  type: string;
  name: string;
  id: string;
  opened?: boolean;
  data?: Entity[];
}

type PersonsDrawerProps = {
  toggle: (open: boolean) => void;
  open?: boolean;
  control?: any;
  data: OrganisationsFilterItem[];
  setFilterRefresh?: any;
};

const PersonsDrawer = ({
  toggle,
  open,
  control,
  data,
  setFilterRefresh,
}: PersonsDrawerProps) => {
  const [search, setSearch] = useState<string>("");
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const filterFields = useMemo(() => {
    const result: OrganisationsFilterItem[] = [];
    if (!search) {
      return data;
    }
    const lowerSearchValue = search.toLowerCase();
    data.forEach(({ house, id, cttees, appgs, parties }) => {
      const isHouseMatch = house.toLowerCase().includes(lowerSearchValue);
      if (isHouseMatch) {
        result.push({ house, id, cttees, appgs, parties });
      } else {
        const filteredCttees = cttees.filter(({ name }) => {
          return name.toLowerCase().includes(lowerSearchValue);
        });
        const filteredAppgs = appgs.filter(({ name }) => {
          return name.toLowerCase().includes(lowerSearchValue);
        });
        const filteredParties = parties.filter(({ name }) => {
          return name.toLowerCase().includes(lowerSearchValue);
        });
        if (
          filteredCttees.length ||
          filteredAppgs.length ||
          filteredParties.length
        ) {
          result.push({
            house,
            id,
            cttees: filteredCttees,
            appgs: filteredAppgs,
            parties: filteredParties,
          });
        }
      }
    });
    if (!result.length)
      return data.map((item) => {
        return {
          house: item.house,
          id: item.id,
          cttees: [],
          appgs: [],
          parties: [],
        };
      });
    return result;
  }, [search, data]);

  return (
    <Box
      height={1060}
      sx={{
        width: matches ? 350 : 250,
        backgroundColor: (theme) => theme.palette.secondary.main,
        borderRight: 1,
        borderColor: "divider",
        overflowY: "scroll",
        maxHeight: "100%",
        borderRadius: "0px 0px 8px 0px",
      }}
    >
      <Stack direction={"column"}>
        <Box p={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h2"} pb={2}>
              People Explorer
            </Typography>
            <CloseIcon onClick={() => toggle(false)} />
          </Stack>
        </Box>
        <Divider />
        <Box p={2} display="flex">
          <TextField
            onChange={handleSearch}
            size="small"
            id="outlined-basic"
            label="Search"
            name="search"
            variant="outlined"
            sx={{
              flex: 1,
              minWidth: "170px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography variant="h3" px={2}>
            Search
          </Typography>
          <List>
            {filterFields.map((house: OrganisationsFilterItem) => {
              return (
                <CollapsableRow
                  header={getOrgNameFromKey(house.house).toUpperCase()}
                  id={house.id}
                  control={control}
                  setFilterRefresh={setFilterRefresh}
                >
                  {house.parties.length > 0 ? (
                    <List sx={{ pl: 2 }}>
                      <CollapsableRow
                        header={"Parties".toLocaleUpperCase()}
                        id={`${house.id}-parties`}
                        control={control}
                        setFilterRefresh={setFilterRefresh}
                      >
                        <List sx={{ pl: 2 }}>
                          {house.parties.map((value) => {
                            return (
                              <CollapsableRow
                                header={value.name.toUpperCase()}
                                collapsable={false}
                                id={value.id}
                                control={control}
                                setFilterRefresh={setFilterRefresh}
                              />
                            );
                          })}
                        </List>
                      </CollapsableRow>
                    </List>
                  ) : null}

                  {house.cttees.length > 0 ? (
                    <List sx={{ pl: 2 }}>
                      <CollapsableRow
                        header={"Committees".toLocaleUpperCase()}
                        id={`${house.id}-committees`}
                        control={control}
                        setFilterRefresh={setFilterRefresh}
                      >
                        <List sx={{ pl: 2 }}>
                          {house.cttees.map((value) => {
                            return (
                              <CollapsableRow
                                header={value.name.toUpperCase()}
                                collapsable={false}
                                id={value.id}
                                control={control}
                                setFilterRefresh={setFilterRefresh}
                              />
                            );
                          })}
                        </List>
                      </CollapsableRow>
                    </List>
                  ) : null}
                  {house.appgs.length > 0 ? (
                    <List sx={{ pl: 2 }}>
                      <CollapsableRow
                        header={"Parliamentary Groups".toLocaleUpperCase()}
                        id={`${house.id}-cross-party-groups`}
                        control={control}
                        setFilterRefresh={setFilterRefresh}
                      >
                        <List sx={{ pl: 2 }}>
                          {house.appgs.map((value) => {
                            return (
                              <CollapsableRow
                                header={value.name.toUpperCase()}
                                collapsable={false}
                                id={value.id}
                                setFilterRefresh={setFilterRefresh}
                                control={control}
                              />
                            );
                          })}
                        </List>
                      </CollapsableRow>
                    </List>
                  ) : null}
                </CollapsableRow>
              );
            })}
          </List>
        </Box>
        <Divider />
      </Stack>
    </Box>
  );
};
export default PersonsDrawer;
