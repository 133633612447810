import Box from "@mui/material/Box";
import { useGetEmailStatus } from "../query/emails";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import { SentEmailStatusEnum } from "../types/Email";

const SentEmailStatusWidget = ({
  personId,
  emailId,
}: {
  personId: string;
  emailId: string;
}) => {
  const { data, isLoading } = useGetEmailStatus(personId, emailId);
  return (
    <>
      {isLoading ? <CircularProgress size={20} /> : null}
      {data && !isLoading ? (
        <Box>
          <Typography variant="body2">
            {data ? SentEmailStatusEnum[data] : ""}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
export default SentEmailStatusWidget;
