import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetPerson } from "../../query/person";
import { truncateText } from "../../services/utils";

type PersonBioDetailRowProps = {
  personId: string;
};

const PersonBioDetailRow = ({ personId }: PersonBioDetailRowProps) => {
  const { data, isLoading } = useGetPerson(personId);

  return (
    <Box
      sx={{
        display: "flex",
        height: 40,
      }}
    >
      {isLoading ? <CircularProgress size={20} /> : null}
      <Box display="flex">
        <Typography variant="body2">
          {truncateText(data?.bio_summary, 220)}
        </Typography>
      </Box>
    </Box>
  );
};
export default PersonBioDetailRow;
