import { useGetUser } from "../../../query/user";

type UserCellProps = {
  userId: string;
};

const UserCell = ({ userId }: UserCellProps) => {
  const { data: user } = useGetUser(userId);
  return <>{user ? `${user.name}` : "Unknown"}</>;
};

export default UserCell;
