import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import { useLocation, Link as RouterLink } from "react-router-dom";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const RouterBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline={"hover"}>
        <RouterLink color="inherit" to="/">
          Home
        </RouterLink>
      </Link>
      {pathnames.map((_, index) => {
        let last = index === pathnames.length - 1;
        const to = `${pathnames.slice(0, index + 1).join("/")}`;

        return last ? null : (
          <Link underline={"hover"}>
            <RouterLink color="inherit" to={to}>
              {capitalizeFirstLetter(to)}
            </RouterLink>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default RouterBreadcrumbs;
