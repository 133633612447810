import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate } from "react-router-dom";

const ErrorBoundaryPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Container>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        my={12}
        mx={16}
        spacing={2}
        textAlign={"center"}
      >
        <Typography
          sx={{
            fontFamily: "'Lato', sans-serif;",
            fontSize: "4rem",
            fontWeight: 900,
          }}
        >
          Oops!
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Lato', sans-serif;",
            fontSize: "2rem",
            fontWeight: 600,
            lineHeight: "2.5rem",
          }}
        >
          We’re sorry, but an error has occurred. Please return to the home page
          an try again.
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Pacifico', cursive",
            fontSize: "30rem",
            fontWeight: 400,
            lineHeight: "16rem",
            color: "#FF64A9",
          }}
        >
          404
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{ top: matches ? "70%" : "90%", position: "absolute" }}
          startIcon={<RestartAltIcon />}
          onClick={() => navigate("/")}
        >
          Return to home
        </Button>
      </Stack>
    </Container>
  );
};

export default ErrorBoundaryPage;
