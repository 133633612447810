import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { USERS, USERS_SELF } from "./constants";
import { getUser, getUserSelf, getUsers } from "../services/UserService";

export function useGetUser(userId: string) {
  return useQuery({
    queryKey: [USERS, userId],
    queryFn: () => getUser(userId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetUserSelf() {
  return useQuery({
    queryKey: [USERS_SELF],
    queryFn: getUserSelf,
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetUsers() {
  return useQuery({
    queryKey: [USERS],
    queryFn: getUsers,

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}
