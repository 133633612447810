
import { LocalStorageKey, StorageService } from "./StorageService";
import { getBasePath } from "./utils";
import { POLIMONITOR_MAIN_APP_URL } from "../constants";



export const getAccessToken = async () => {
    const accessTokenPath = "/auth/access_token"
    const response: Response = await fetch(getBasePath() + accessTokenPath, {
        credentials: 'include'
    });
    if (!response.ok) {
        // TODO: handle more errors
        if (response.status === 401) {
            window.location.replace(POLIMONITOR_MAIN_APP_URL + '/signin/');
        }
    } else {
        try {
            const token = await response.json();
            StorageService.Set(LocalStorageKey.Jwt, token.access_token);
        } catch (e) {
            console.log(e);
        }
    }
}


