import {
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";

import EmailsContainer from "./EmailsTab";
import { useLocation, useNavigate } from "react-router-dom";

const EmailsPage = () => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(() => {
    if (location.pathname.includes("sent")) {
      return 0;
    } else if (location.pathname.includes("draft")) {
      return 1;
    }
    return 0;
  });
  const navigate = useNavigate();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <Container maxWidth="xl">
      <Stack
        direction={"row"}
        py={4}
        borderBottom={1}
        borderColor={"divider"}
        pr={8}
        pl={0}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="h1">Emails</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            navigate(`/emails/create`);
          }}
          size="small"
        >
          + Create new email broadcast
        </Button>
      </Stack>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Sent emails" value={0} />
        <Tab label="Draft emails" value={1} />
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 ? <EmailsContainer status="sent" /> : null}
        {tabValue === 1 ? <EmailsContainer status="draft" /> : null}
      </Box>
    </Container>
  );
};

export default EmailsPage;
