import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createEmail,
  getEmail,
  getEmailPreviewForPerson,
  getEmails,
  getSentEmail,
  getSentEmailDeliveryStats,
  getSentEmailsForPerson,
  getSentEmailStatus,
  sendEmail,
  updateEmail,
} from "../services/EmailService";
import {
  EMAIL_STATUS,
  EMAILS,
  SENT_EMAILS,
  SENT_EMAILS_STATS,
} from "./constants";
import { NotificationService } from "../services/NotificationService";
import { EmailSearchValues } from "../types/Email";

export function useEmails(
  offset: number = 0,
  limit: number = 25,
  filter?: EmailSearchValues,
) {
  return useQuery({
    queryKey: [
      EMAILS,
      offset,
      limit,
      filter?.q,
      filter?.sent_by,
      filter?.status,
    ],
    queryFn: () =>
      getEmails(offset, limit, filter?.q, filter?.sent_by, filter?.status),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetEmail(emailId: string) {
  return useQuery({
    queryKey: [EMAILS, emailId],
    queryFn: () => getEmail(emailId),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export const useCreateEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAILS] });
      NotificationService.Success("Email created");
    },
  });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAILS] });
      NotificationService.Success("Email updated");
    },
  });
};

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAILS] });
      NotificationService.Success("Email sent");
    },
  });
};

export function usePersonEmails(
  offset: number = 0,
  limit: number = 25,
  personId: string,
  q?: string,
) {
  return useQuery({
    queryKey: [SENT_EMAILS, offset, limit, personId, q],
    queryFn: () => getSentEmailsForPerson(offset, limit, personId, q),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}
export function useGetEmailStatus(personId: string, emailId: string) {
  return useQuery({
    queryKey: [EMAIL_STATUS, personId, emailId],
    queryFn: () => getSentEmailStatus(personId, emailId),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetSentEmail(sentEmailId: string) {
  return useQuery({
    queryKey: [SENT_EMAILS, sentEmailId],
    queryFn: () => getSentEmail(sentEmailId),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetSentEmailDeliveryStats(sentEmailId: string) {
  return useQuery({
    queryKey: [SENT_EMAILS_STATS, sentEmailId],
    queryFn: () => getSentEmailDeliveryStats(sentEmailId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetEmailPreviewForPerson(emailId: string, personId: string) {
  return useQuery({
    queryKey: [SENT_EMAILS, emailId, personId],
    queryFn: () => getEmailPreviewForPerson(emailId, personId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}
