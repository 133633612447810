import { useMemo, useState } from "react";
import {
  ContentType,
  ContentWidgetContent,
  Pagination,
  SearchContentType,
  SearchFilters,
} from "../types/Search";
import { useContentForPersonSearch, useContentTypes } from "../query/search";

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack } from "@mui/material";
import ContentCard from "./ContentCard";
import useInfiniteScroll from "react-infinite-scroll-hook";

type ContentWidgetProps = {
  filter: SearchFilters;
  selectedPersonId: string;
};

const ContentWidget = ({ filter, selectedPersonId }: ContentWidgetProps) => {
  const [paginationContent] = useState<Pagination>({ offset: 0, limit: 25 });

  const { data: contentTypes } = useContentTypes();
  const {
    data: contentRaw,
    isLoading: contentLoading,
    hasNextPage,
    loadMore,
    error,
  } = useContentForPersonSearch(
    paginationContent.limit,
    "id",
    selectedPersonId,
    filter,
  );

  const [sentryRef] = useInfiniteScroll({
    loading: contentLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const content = useMemo<ContentWidgetContent[] | undefined>(() => {
    if (contentRaw?.length && contentTypes?.length) {
      return contentRaw.map((item: SearchContentType) => {
        return {
          ...item,
          type: contentTypes.find(
            (type: ContentType) => type.id === item.content_type,
          ),
        };
      });
    }
  }, [contentRaw, contentTypes]);
  return (
    <>
      {contentLoading && !content ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "60%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {content && content.length ? (
        <Box display="flex" flexDirection={"column"} gap={3}>
          {content.map((item: ContentWidgetContent) => (
            <ContentCard content={item} />
          ))}
          {(contentLoading || hasNextPage) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
              ref={sentryRef}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default ContentWidget;
