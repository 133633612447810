import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CKEditorComponent from "../../../components/CKEditorComponent";
import AttachmentLinkWidget from "../../../components/AttachmentsLinkWidget";

import { useGetSentEmail } from "../../../query/emails";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import ModalLayout from "../../../components/ModalLayout";

type SentEmailModalProps = {
  setOpen: (arg: any) => void;
  open: boolean;
  emailId: string;
};

const SentEmailModal = ({ setOpen, open, emailId }: SentEmailModalProps) => {
  const { data: email } = useGetSentEmail(emailId);

  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      {email ? (
        <Stack direction={"column"} spacing={1} pt={2} pb={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h1">{email?.subject}</Typography>
            <CloseIcon onClick={() => setOpen(!open)} />
          </Stack>

          <Stack py={4} spacing={2}>
            <TextField
              value={email?.subject}
              id="outlined-basic"
              label="Subject:"
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
            <Stack direction={"row"} spacing={2}>
              <TextField
                value={email?.from_field}
                id="outlined-basic"
                label="From:"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
              <TextField
                value={email?.replyTo}
                id="outlined-basic"
                label="Reply to:"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Stack>

            <Stack direction={"row"} spacing={2}></Stack>

            <Stack direction={"column"} spacing={1}>
              <Box display="inline-flex">
                <Typography variant="h4">Email Content</Typography>
              </Box>

              <Box
                sx={{
                  ".ck-editor__editable": {
                    height: "200px",
                  },
                }}
              >
                <CKEditorComponent
                  data={email.content ? email.content : ""}
                  disabled
                  onReady={() => {}}
                  onChange={() => {}}
                />
              </Box>
            </Stack>
            {email.attachments ? (
              <Box>
                {email.attachments.map((att, ind) => {
                  return <AttachmentLinkWidget key={ind} attachmentId={att} />;
                })}
              </Box>
            ) : null}
          </Stack>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            pt={2}
            pb={2}
            gap={1}
            sx={{
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpen(!open)}
            >
              Cancel{" "}
            </Button>
          </Box>
        </Stack>
      ) : null}
    </ModalLayout>
  );
};

export default SentEmailModal;
