import Grid from "@mui/material/Grid";
import { PersonStaff } from "../../../types/Person";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const StaffCell = ({ staff }: { staff: PersonStaff[] }) => {
  return (
    <Grid item xs={10}>
      <Typography variant="h2" py={2}>
        Staff
      </Typography>
      <Grid container spacing={2}>
        {staff.map((staff) => (
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h3">{staff.staff_fullname}</Typography>
              <Typography variant="h4">
                {staff.staff_email_verified || staff.staff_email}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default StaffCell;
