import Box from "@mui/material/Box";
import { usePersonsAll } from "../../query/person";
import { Avatar, AvatarGroup, CircularProgress } from "@mui/material";
import { getInitialsFromName } from "./utils";

type PersonsBadgesWidgetProps = {
  ids: string[];
  sliceCount?: number;
};

const PersonsBadgesWidget = ({
  ids,
  sliceCount = 5,
}: PersonsBadgesWidgetProps) => {
  const { data: persons, isLoading } = usePersonsAll();
  const filtered = persons?.results
    .filter((p) => ids.includes(p.id))
    .slice(0, sliceCount);
  return (
    <Box width={"fit-content"}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <AvatarGroup
          max={5}
          total={ids.length}
          sx={{
            ".MuiAvatar-root": {
              width: "40px",
              height: "40px",
            },
          }}
        >
          {filtered?.map((person) => {
            return (
              <Avatar
                key={person.id}
                alt={getInitialsFromName(person.name)}
                src={person?.img_src}
              />
            );
          })}
        </AvatarGroup>
      )}
    </Box>
  );
};
export default PersonsBadgesWidget;
