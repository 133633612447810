import { Box, Typography } from "@mui/material";

type CounterBoxProps = {
  count: number;
  disabled?: boolean;
};

const CounterBox = ({ count, disabled }: CounterBoxProps) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        minWidth: "20px",
        alignContent: "center",
        height: "20px",
        display: disabled ? "none" : "block",
        background: (theme) => theme.palette.primary.light,
      }}
    >
      <Typography sx={{ fontSize: "13px", color: "white", fontWeight: 700 }}>
        {count}
      </Typography>
    </Box>
  );
};

export default CounterBox;
