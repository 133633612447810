import { useMemo } from "react";
import { DrawerStateFilterType, OrganisationsFilterItem } from ".";
import { Appg, Committee, Person } from "../../types/Person";

export const useOrganisationsFilterData = (
  persons: Person[],
  committees: Committee[],
  crossPartyGroups: Appg[],
) => {
  const organisationsFilterData = useMemo<OrganisationsFilterItem[]>(() => {
    const houseMap: Record<
      string,
      {
        house: string;
        id: string;
        cttees: { name: string; id: string }[];
        appgs: { name: string; id: string }[];
        parties: { name: string; id: string }[];
      }
    > = {};

    if (persons?.length && committees && crossPartyGroups) {
      persons?.forEach((person) => {
        if (
          person.organization &&
          ["HoL", "HoC", "NIASSEMBLY", "Senedd", "SCOTPARL"].includes(
            person.organization,
          )
        ) {
          if (!houseMap[person.organization]) {
            houseMap[person.organization] = {
              house: person.organization,
              id: person.organization,
              cttees: [],
              appgs: [],
              parties: [],
            };
          }

          person.cttees?.forEach((ctteePerson) => {
            const committeeData = committees.find(
              (cttee) => cttee.id === ctteePerson.id,
            );
            if (
              committeeData &&
              person?.organization &&
              !houseMap[person?.organization].cttees.find(
                (cttee) => cttee.id === committeeData.id,
              )
            ) {
              houseMap[person.organization].cttees.push({
                name: committeeData.name,
                id: committeeData.id,
              });
            }
          });
          person.appgs?.forEach((appgPerson) => {
            const appgData = crossPartyGroups.find(
              (appg) => appg.id === appgPerson.id,
            );
            if (
              appgData &&
              person?.organization &&
              !houseMap[person?.organization].appgs.find(
                (appg) => appg.id === appgData.id,
              )
            ) {
              houseMap[person.organization].appgs.push({
                name: appgData.name,
                id: appgData.id,
              });
            }
          });
          if (
            person.party &&
            !houseMap[person.organization].parties.find(
              (party) => party.id === person.party,
            )
          ) {
            houseMap[person.organization].parties.push({
              name: person.party,
              id: person.party,
            });
          }
        }
      });
    }
    return Object.values(houseMap);
  }, [committees, crossPartyGroups, persons]);
  return organisationsFilterData;
};

export const useFilteredPersons = (
  persons: Person[],
  filterState: DrawerStateFilterType,
) => {
  const personsData = useMemo(() => {
    const filteredResults = persons?.filter((person) => {
      if (
        filterState.houses.length === 0 &&
        filterState.committees.length === 0 &&
        filterState.crossPartyGroups.length === 0
      )
        return true;

      const houseCheck = filterState.houses.includes(
        person?.organization as string,
      );

      const committeeCheck = person.cttees?.some((cttee) =>
        filterState.committees.includes(cttee.id),
      );
      const appgCheck = person.appgs?.some((appg) =>
        filterState.crossPartyGroups.includes(appg.id),
      );
      const partyCheck = filterState.parties.includes(person?.party as string);

      return houseCheck && (committeeCheck || appgCheck || partyCheck);
    });

    return {
      ...persons,
      results: filteredResults,
    };
  }, [
    filterState.committees,
    filterState.crossPartyGroups,
    filterState.houses,
    filterState.parties,
    persons,
  ]);
  return personsData;
};
