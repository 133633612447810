import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateListMutation,
  useListsAll,
  useUpdateListMutation,
} from "../query/list";
import { useForm } from "react-hook-form";
import { useMemo, useState } from "react";

import useColors from "../containers/Lists/colors";
import ModalLayout from "./ModalLayout";

type AddOrCreateListModalProps = {
  people: string[];
  setSelected?: (selected: string[]) => void;
  open: boolean;
  toggle: (open: boolean) => void;
};

const AddOrCreateListModal = ({
  people,
  open,
  toggle,
  setSelected,
}: AddOrCreateListModalProps) => {
  const { data: lists, isLoading: listsLoading } = useListsAll();
  const { data: colors } = useColors();
  const { mutate: createList } = useCreateListMutation();
  const { mutate: updateList } = useUpdateListMutation();
  const [selectedList, setSelectedList] = useState<string>("");

  const dropSelection = () => {
    if (setSelected) setSelected([]);
  };

  const selectedListEntity = useMemo(() => {
    if (lists)
      return lists.results.filter((list) => list.id === selectedList)[0];
  }, [lists, selectedList]);

  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      list_name: "",
      category_name: "",
      color: "",
    },
  });
  const onSubmit = () => {
    if (selectedListEntity) {
      const newPersons = (() => {
        if (selectedListEntity.person_ids) {
          const seen: Record<string, boolean> = {};
          const mergedPersonIds = [...selectedListEntity.person_ids, ...people];
          return mergedPersonIds.filter((personId) => {
            return seen.hasOwnProperty(personId)
              ? false
              : (seen[personId] = true);
          });
        }

        return people;
      })();
      updateList(
        { list_id: selectedListEntity.id, data: { person_ids: newPersons } },
        {
          onSuccess: () => {
            toggle(false);
            dropSelection();
          },
        },
      );
    } else {
      const { category_name, list_name, color } = watch();
      createList(
        {
          category: category_name,
          list_name,
          color,
          person_ids: people,
        },
        {
          onSuccess: () => {
            toggle(false);
            dropSelection();
          },
        },
      );
    }
  };

  return (
    <ModalLayout open={open} toggle={toggle}>
      {listsLoading ? <CircularProgress /> : null}
      {lists ? (
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={"column"} spacing={3} pt={3} pb={6}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h2"} pb={2}>
                  Add {people.length} people to selected list
                </Typography>
                <CloseIcon onClick={() => toggle(false)} />
              </Stack>

              <FormControl>
                <InputLabel>Select list</InputLabel>
                <Select
                  value={selectedList}
                  label="Select list"
                  onChange={(event) => setSelectedList(event.target.value)}
                >
                  <MenuItem key={"clear"} value={undefined}>
                    Clear selection
                  </MenuItem>
                  {lists.results.map((list) => {
                    return (
                      <MenuItem key={list.id} value={list.id}>
                        {list.list_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Divider>
                <Typography variant={"subtitle2"}>OR</Typography>
              </Divider>
              <Typography variant={"h4"}>Create a new list</Typography>
              <TextField
                {...register("list_name", {
                  required: selectedList ? false : true,
                  maxLength: 100,
                })}
                required={selectedList ? false : true}
                disabled={selectedList ? true : false}
                id="outlined-required"
                label="List name"
                placeholder="Enter list name"
              />
              <TextField
                {...register("category_name", {
                  required: selectedList ? false : true,
                  maxLength: 100,
                })}
                required={selectedList ? false : true}
                disabled={selectedList ? true : false}
                id="outlined-required"
                label="Category name"
                placeholder="Enter category name"
              />
              <FormControl>
                <InputLabel disabled={selectedList ? true : false}>
                  Color
                </InputLabel>
                <Select
                  {...register("color", {
                    required: selectedList ? false : true,
                  })}
                  label={"Color"}
                  disabled={selectedList ? true : false}
                  required={selectedList ? false : true}
                >
                  {colors?.map((dataItem) => (
                    <MenuItem key={dataItem.hex} value={dataItem.hex}>
                      <Stack direction={"row"} gap={1}>
                        <Box
                          width={16}
                          height={16}
                          sx={{
                            backgroundColor: dataItem?.hex,
                            borderRadius: "4px",
                          }}
                        />
                        {dataItem.name}
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              py={2}
              gap={1}
              sx={{
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Button variant="outlined" onClick={() => toggle(false)}>
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                Confirm
              </Button>
            </Box>
          </form>
        </Box>
      ) : null}
    </ModalLayout>
  );
};

export default AddOrCreateListModal;
