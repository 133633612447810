export enum LocalStorageKey {
  Jwt = "jwt",
}

export const StorageService = {
  Get: (key: LocalStorageKey) => {
    return localStorage.getItem(key);
  },
  Set: (key: LocalStorageKey, value: string) => {
    return localStorage.setItem(key, value);
  },
  Remove: (key: LocalStorageKey) => {
    return localStorage.removeItem(key);
  },
};
