import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";

const Footer = () => {
  return (
    <BottomNavigation
      component={Box}
      gap={1}
      sx={{
        borderTop: 1,
        borderColor: "divider",
        py: "28px",
        px: "42px",
        width: "100%",
        position: "fixed",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        zIndex: 1000,
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#848E9C",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "140%",
          }}
          color="text.secondary"
        >
          © Copyright 2024 All Rights Reserved
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <Typography
          component="a"
          target="_blank"
          href="#"
          sx={{
            color: "#848E9C",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "140%",
          }}
          color="text.secondary"
        >
          About us
        </Typography>
        <Typography
          component="a"
          target="_blank"
          href="#"
          sx={{
            color: "#848E9C",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "140%",
          }}
          color="text.secondary"
        >
          Terms & Conditions
        </Typography>

        <Typography
          component="a"
          target="_blank"
          href="#"
          sx={{
            color: "#848E9C",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "140%",
          }}
          color="text.secondary"
        >
          Privacy Policy
        </Typography>
      </Box>
    </BottomNavigation>
  );
};

export default Footer;
