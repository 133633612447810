import { useMemo, useState } from "react";
import ModalLayout from "../../../components/ModalLayout";
import { useGetEmailPreviewForPerson } from "../../../query/emails";
import { EmailType } from "../../../types/Email";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CKEditorComponent from "../../../components/CKEditorComponent";
import Button from "@mui/material/Button";
import AttachmentLinkWidget from "../../../components/AttachmentsLinkWidget";
import MenuItem from "@mui/material/MenuItem";
import { Person } from "../../../types/Person";
import CircularProgress from "@mui/material/CircularProgress";

type EmailPreviewModalProps = {
  email: EmailType;
  persons: Person[];
  personId: string;
  open: boolean;
  setOpen: (arg: any) => void;
};

const EmailPreviewModal = ({
  email,
  persons,
  personId,
  open,
  setOpen,
}: EmailPreviewModalProps) => {
  const [selectedPersonId, setSelectedPersonId] = useState(personId);
  const { data: emailPreview, isLoading } = useGetEmailPreviewForPerson(
    email.id,
    selectedPersonId,
  );

  const emailsMapping = useMemo(() => {
    return persons
      .filter((person) => email.recipients?.includes(person.id))
      .map((person) => {
        return { personId: person.id, email: person.email_address };
      });
  }, [persons, email.recipients]);

  const getEmailById = (id: string) =>
    persons.find((person) => person.id === id)?.email_address;

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "60%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      <ModalLayout open={open} toggle={() => setOpen(!open)}>
        {emailPreview ? (
          <Stack direction={"column"} spacing={1} pt={2} pb={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h1">{emailPreview?.subject}</Typography>
              <CloseIcon onClick={() => setOpen(!open)} />
            </Stack>

            <Stack py={4} spacing={2}>
              <TextField
                value={selectedPersonId}
                onChange={(e) => setSelectedPersonId(e.target.value)}
                required
                select
                label="Recipient"
                defaultValue={getEmailById(selectedPersonId)}
                helperText={"Select a recipient to preview the email"}
              >
                {emailsMapping.map((recipient, index) => {
                  return (
                    <MenuItem key={index} value={recipient.personId}>
                      {recipient.email}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                value={emailPreview?.subject}
                id="outlined-basic"
                label="Subject:"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
              <Stack direction={"row"} spacing={2}>
                <TextField
                  value={emailPreview?.from_field}
                  id="outlined-basic"
                  label="From:"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
                <TextField
                  value={emailPreview?.replyTo}
                  id="outlined-basic"
                  label="Reply to:"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Stack>

              <Stack direction={"row"} spacing={2}></Stack>

              <Stack direction={"column"} spacing={1}>
                <Box display="inline-flex">
                  <Typography variant="h4">Email Content</Typography>
                </Box>

                <Box
                  sx={{
                    ".ck-editor__editable": {
                      height: "200px",
                    },
                  }}
                >
                  <CKEditorComponent
                    data={emailPreview.content ? emailPreview.content : ""}
                    disabled
                    onReady={() => {}}
                    onChange={() => {}}
                  />
                </Box>
              </Stack>
              {emailPreview.attachments ? (
                <Box>
                  {emailPreview.attachments.map((att, ind) => {
                    return (
                      <AttachmentLinkWidget key={ind} attachmentId={att} />
                    );
                  })}
                </Box>
              ) : null}
            </Stack>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              pt={2}
              pb={2}
              gap={1}
              sx={{
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpen(!open)}
              >
                Back{" "}
              </Button>
            </Box>
          </Stack>
        ) : null}
      </ModalLayout>
    </>
  );
};

export default EmailPreviewModal;
