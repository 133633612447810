import {
  InputAdornment,
  TextField,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import { useContentTypes, useParties, useRegions } from "../query/search";
import { useListsAll } from "../query/list";
import CircularProgress from "@mui/material/CircularProgress";
import { SearchFilters } from "../types/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import HookedMultiSelect from "./HookedMultiSelect";
import DateRangeSelect from "./DateRangeSelect";

type OmniSearchFilterProps = {
  setFilter: (filter: SearchFilters) => void;
};

const OmniSearchFilter = ({ setFilter }: OmniSearchFilterProps) => {
  const { data: regions, isLoading: regionsLoading } = useRegions();
  const { data: contentTypes, isLoading: contentTypesLoading } =
    useContentTypes();
  const { data: parties, isLoading: partiesLoading } = useParties();
  const { data: lists, isLoading: listsLoading } = useListsAll();

  const matches = useMediaQuery("lg");

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      party: [],
      region: [],
      gender: [],
      content_type: [],
      lists: [],
      date_from: "",
      date_to: "",
      q: "",
    },
  });
  const onSubmit = (data: any) => {
    setFilter({
      ...data,
      date_from: data.date_from,
      date_to: data.date_to,
    });
  };

  return (
    <>
      {regionsLoading ||
      contentTypesLoading ||
      partiesLoading ||
      listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            pt={6}
            pb={4}
            px={5}
            gap={4}
            direction={"column"}
            sx={{
              backgroundColor: "secondary.main",
              ".MuiSvgIcon-root": {
                color: "common.black",
              },
            }}
          >
            <Typography variant="h1">Search for Content</Typography>

            <Box
              display="flex"
              gap={2}
              flexWrap={matches ? "nowrap" : "wrap"}
              maxHeight={"200px"}
            >
              <Controller
                name="q"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    id="outlined-basic"
                    label="Search for content"
                    name="search"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      minWidth: "170px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <DateRangeSelect setValue={setValue} />

              <HookedMultiSelect
                fieldName="party"
                control={control}
                labelName="Party"
                data={parties}
                selection={watch("party")}
                itemLabelName="name"
              />

              <HookedMultiSelect
                fieldName="region"
                control={control}
                labelName="Region"
                valueField="name"
                data={regions}
                selection={watch("region")}
                itemLabelName="name"
              />

              <HookedMultiSelect
                fieldName="content_type"
                control={control}
                labelName="Type"
                data={contentTypes}
                selection={watch("content_type")}
                itemLabelName="name"
              />

              <HookedMultiSelect
                fieldName="lists"
                control={control}
                labelName="Lists"
                data={lists?.results}
                selection={watch("lists")}
                itemLabelName="list_name"
              />

              <Button
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  minWidth: "100px",
                }}
              >
                Search
              </Button>
            </Box>
          </Stack>
        </form>
      )}
    </>
  );
};

export default OmniSearchFilter;
