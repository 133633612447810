import Box from "@mui/material/Box";
import { usePersonBio } from "../../../query/person";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography, Card, SvgIcon, Divider } from "@mui/material";
import { BioItem } from "../../../types/ParlyApi";
import { getOrgIconFromKey } from "../../../components/utils";

type PersonCareerProps = {
  parly_uid: string;
};

type CareerCardProps = {
  content: BioItem;
};

const CareerCard = ({ content }: CareerCardProps) => {
  const houseIcon =
    content.house === 1 ? getOrgIconFromKey("HoC") : getOrgIconFromKey("HoL");

  return (
    <Card variant="outlined" sx={{ maxWidth: 360, height: 148 }}>
      <Stack direction="column" spacing={1} padding={2}>
        <Stack direction="row">
          <SvgIcon inheritViewBox component={houseIcon as any} />
        </Stack>
        <Typography variant="h3">{content.name}</Typography>
        <Typography variant="subtitle2">{content.additionalInfo}</Typography>

        <Stack direction="row">
          <Typography variant="subtitle2">
            {new Date(content.startDate).toLocaleDateString("en-GB")}
          </Typography>
          <Typography variant="subtitle2">{"\u00A0-\u00A0"}</Typography>
          <Typography variant="subtitle2">
            {content.endDate
              ? new Date(content.endDate).toLocaleDateString("en-GB")
              : "Present"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

const PersonCareer = ({ parly_uid }: PersonCareerProps) => {
  const { data: bio, isLoading } = usePersonBio(parly_uid);
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {bio && bio.value ? (
        <Stack direction="column" spacing={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2">Current Parliamentary Career</Typography>
            </Grid>
            {bio.value.representations
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Representation</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.governmentPosts
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Government Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.oppositionPosts
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Opposition Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.committeeMemberships
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Committee Membership</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
          </Grid>
          <Divider />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2">
                Previous Parliamentary Career
              </Typography>
            </Grid>
            {bio.value.representations
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Representation</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.governmentPosts
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Government Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.oppositionPosts
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Opposition Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.committeeMemberships
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Committee Membership</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </Stack>
      ) : null}
    </>
  );
};

export default PersonCareer;
