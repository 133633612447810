import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";

import { EmailType } from "../../types/Email";
import { useNavigate } from "react-router-dom";

const EmailsTable = ({
  columns,
  data,
  totalCount,
  setPagination,
  pagination,
  isFetching,
}: {
  columns: MRT_ColumnDef<EmailType>[];
  data: EmailType[];
  totalCount: number;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  pagination: any;
  isFetching: boolean;
}) => {
  const navigate = useNavigate();
  const table = useMaterialReactTable({
    columns,
    data,
    manualPagination: true,
    enableSorting: false,
    // manualSorting: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: false,
    enableExpandAll: false,
    rowCount: totalCount ?? 0,
    onPaginationChange: setPagination,
    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/emails/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),

    state: {
      pagination,
      isLoading: isFetching,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default EmailsTable;
