import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createNote,
  createNotesBulk,
  deleteNote,
  getNote,
  updateNote,
} from "../services/NotesService";
import { NotificationService } from "../services/NotificationService";
import { NOTES } from "./constants";

export const useCreateNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNote,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [NOTES] });
      NotificationService.Success("Note created");
    },
  });
};

export const useCreateNotesBulkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNotesBulk,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: [NOTES] });
      NotificationService.Success(result);
    },
  });
};

export const useUpdateNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNote,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: [NOTES] });
      NotificationService.Success("Note updated!");
    },
  });
};
export const useDeleteNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNote,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: [NOTES] });
      NotificationService.Warning("Note deleted");
    },
  });
};

export function useGetNote(noteId: string) {
  return useQuery({
    queryKey: [NOTES, noteId],
    queryFn: () => getNote(noteId),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}
