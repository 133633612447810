import {
  Avatar,
  Container,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_RowSelectionState,
  MRT_TableHeadCellFilterContainer,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { Person, PersonListsJoinType } from "../../types/Person";

import ListsBadgesWidget from "../../components/MuiTable/ListsBadgesWidget";

import PersonBioDetail from "../../components/MuiTable/PersonBioDetail";

import { ProfileList } from "../../types/List";
import { getOrgNameFromKey } from "./utils";
import { useListsAll } from "../../query/list";
import { useNavigate } from "react-router-dom";

type PersonsTableContainerProps = {
  persons: Person[];
  selected: string[];
  setSelected?: (selected: string[]) => void;
  drawerOpen?: boolean;
  initialFilter?: string;
};

const PersonsTableContainer = ({
  persons,
  setSelected,
  selected,
  drawerOpen,
  initialFilter,
}: PersonsTableContainerProps) => {
  const { data: lists } = useListsAll();

  const personsListsJoin = useMemo(() => {
    if (lists && persons) {
      const joinedPersons = persons.map((person) => {
        const filtered = lists.results.filter((list) =>
          list.person_ids?.includes(person.id),
        );
        return { ...person, lists: filtered };
      });
      return joinedPersons;
    }
  }, [lists, persons]);

  return (
    <>
      {personsListsJoin && personsListsJoin.length ? (
        <PersonsTable
          persons={personsListsJoin}
          lists={lists?.results || []}
          setSelected={setSelected}
          selected={selected}
          drawerOpen={drawerOpen}
          initialFilter={initialFilter}
        />
      ) : null}
    </>
  );
};

type PersonsTableProps = {
  persons: PersonListsJoinType[];
  selected: string[];
  lists: ProfileList[];
  setSelected?: (selected: string[]) => void;
  drawerOpen?: boolean;
  initialFilter?: string;
};

const PersonsTable = ({
  persons,
  setSelected,
  lists,
  selected,
  drawerOpen,
  initialFilter,
}: PersonsTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const getCellWidth = (drawerOpen: boolean | undefined, matches: boolean) => {
    if (matches && !drawerOpen) {
      return 200;
    } else if (matches && drawerOpen) {
      return 150;
    } else if (!matches && !drawerOpen) {
      return 80;
    } else if (drawerOpen) {
      return 40;
    }
  };
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const columns = useMemo<MRT_ColumnDef<PersonListsJoinType>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Profile",
        Cell: ({ row, renderedCellValue }) => (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Avatar src={row.original.img_src} />
            {renderedCellValue}
          </Stack>
        ),
      },
      {
        accessorKey: "organization",
        header: "Organization",

        accessorFn: (row) =>
          row.organization ? getOrgNameFromKey(row.organization) : "N/A",

        filterVariant: "multi-select",
        size: getCellWidth(drawerOpen, matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "organization",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "House";
            },
          },
        },
      },
      {
        accessorKey: "region",
        accessorFn: (row) => (row.region ? row.region : "N/A"),
        header: "Region",
        filterVariant: "multi-select",
        size: getCellWidth(drawerOpen, matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "region",
          size: "small",
          variant: "outlined",

          SelectProps: {
            // autoWidth: true,
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Region";
            },
          },
        },
      },
      {
        header: "Party",
        accessorKey: "party",
        size: getCellWidth(drawerOpen, matches),
      },
      {
        accessorKey: "constituency",
        header: "Constituency",
        size: getCellWidth(drawerOpen, matches),

        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "constituency",
          size: "small",
          variant: "outlined",
          placeholder: "Constituency",
        },
      },
      {
        //TODO: add color coding via renderColumnFilterModeMenuItems
        filterVariant: "multi-select",
        accessorKey: "lists",
        size: getCellWidth(drawerOpen, matches),
        accessorFn: (row) => {
          return row?.lists ? row?.lists.map((list) => list.list_name) : [];
        },
        filterSelectOptions: lists?.map((list) => list.list_name),
        header: "Lists",

        Cell: ({ row }) => <ListsBadgesWidget personId={row.original?.id} />,
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "lists",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Lists";
            },
          },
        },
      },
    ],
    [lists, drawerOpen, matches],
  );
  const table = useMaterialReactTable({
    columns,
    data: persons,

    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    enableFacetedValues: true,
    enableSorting: false,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    selectAllMode: "all",

    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/people/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),

    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        // right: ["mrt-row-actions"],
      },

      showGlobalFilter: true,
      columnFilters: (() =>
        initialFilter
          ? [
              {
                id: "organization",
                value: [getOrgNameFromKey(initialFilter)],
              },
            ]
          : [])(),
    },

    enableExpandAll: false,
    muiDetailPanelProps: {
      sx: {
        width: "auto",
      },
    },

    renderDetailPanel: ({ row }) =>
      row.original.id ? <PersonBioDetail personId={row.original.id} /> : null,
  });

  useEffect(() => {
    if (rowSelection && setSelected) {
      setSelected(
        table.getSelectedRowModel().rows.map((row) => row.original.id),
      );
    }
  }, [persons, setSelected, rowSelection, table]);

  useEffect(() => {
    if (selected.length === 0) {
      setRowSelection({});
    }
  }, [selected]);

  return (
    <Container maxWidth="xl">
      {/* 
    - control how the filters container positioned and looks like
    - control how the select components look like
    - control how the actions look like
    - control what happens when a checkbox is selected
    */}

      <Grid container display={"inline-flex"} alignItems={"center"}>
        <Grid item md={3} mr={1}>
          <MRT_GlobalFilterTextField table={table} />
        </Grid>
        <Grid container py={3} spacing={2} md={9}>
          {table.getLeafHeaders().map((header) => {
            return ["constituency", "region", "lists", "organization"].includes(
              header.id,
            ) ? (
              <Grid item md={3}>
                <MRT_TableHeadCellFilterContainer
                  key={header.id}
                  header={header}
                  table={table}
                  in
                />
              </Grid>
            ) : null;
          })}
        </Grid>
      </Grid>

      <MaterialReactTable table={table} />
    </Container>
  );
};
export default PersonsTableContainer;
