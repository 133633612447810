import CircularProgress from "@mui/material/CircularProgress";
import { useGetSentEmailDeliveryStats } from "../../../query/emails";

const DeliveryStatusCell = ({ emailId }: { emailId: string }) => {
  const { data, isLoading } = useGetSentEmailDeliveryStats(emailId);

  return (
    <>
      {isLoading ? <CircularProgress /> : null}
      {data ? `${data.delivered}% / ${data.opened}%` : "Unknown"}
    </>
  );
};

export default DeliveryStatusCell;
