import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type GridCellProps = {
  title: string;
  content?: string | string[];
  cellSize: number;
  bold?: boolean;
};

const GridCell = ({ title, content, cellSize, bold }: GridCellProps) => {
  return (
    <Grid item xs={cellSize}>
      <Typography variant={bold ? "h2" : "h4"} mb={bold ? 2 : 0.5}>
        {title}
      </Typography>
      {Array.isArray(content) ? (
        content.map((item) => (
          <Typography variant={bold ? "body2" : "h3"}>{item}</Typography>
        ))
      ) : (
        <Typography variant={bold ? "body2" : "h3"}>{content}</Typography>
      )}
    </Grid>
  );
};

export default GridCell;
