import { API_PATHS } from "../constants";
import {
  ProfileList,
  ProfileListInput,
  ProfileListUpdateInput,
} from "../types/List";
import { PaginatedResponse } from "../types/utils";
import { HttpMethod, secureRequest } from "./ApiService";

export const getLists = async (
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
  ids?: string[],
): Promise<PaginatedResponse<ProfileList>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  if (ids && ids.length) {
    ids.forEach((id) => searchParams.append("id", String(id)));
  }

  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.LISTS + "/?" + searchParams,
    {},
  );
};

export const getListsAll = async (): Promise<
  PaginatedResponse<ProfileList>
> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.LISTS_ALL, {});
};

export const getList = async (listId: string): Promise<ProfileList> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.LISTS + "/" + listId,
    {},
  );
};

export const createList = async (
  data: ProfileListInput,
): Promise<ProfileList> => {
  return await secureRequest(HttpMethod.Post, API_PATHS.LISTS, { data });
};

type UpdateListInput = {
  list_id: string;
  data: ProfileListUpdateInput;
};

export const updateList = async ({
  list_id,
  data,
}: UpdateListInput): Promise<ProfileList> => {
  return await secureRequest(
    HttpMethod.Patch,
    `${API_PATHS.LISTS}/${list_id}`,
    { data },
  );
};

// export const addPersonsToList = async ({
//   list_id,
//   person_ids,
// }: AddPersonsToListInput): Promise<string> => {
//   return await secureRequest(
//     HttpMethod.Post,
//     `${API_PATHS.LISTS}/${list_id}/add-persons`,
//     { data: { person_ids } },
//   );
// };

// export const removePersonsFromList = async ({
//   list_id,
//   person_ids,
// }: AddPersonsToListInput): Promise<string> => {
//   return await secureRequest(
//     HttpMethod.Post,
//     `${API_PATHS.LISTS}/${list_id}/remove-persons`,
//     { data: { person_ids } },
//   );
// };
