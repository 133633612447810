import TextField from "@mui/material/TextField";

import { Controller, FieldError, Control } from "react-hook-form";

type HookedFormFieldPropsType = {
  name: string;
  control: Control<any>;
  label: string;
  helperText?: string;
  error?: FieldError;
  required?: boolean;
  children?: React.ReactNode[];
  selectDefaultValue?: string;
  [x: string]: any;
};

const HookedFormSelectField = ({
  control,
  error,
  name,
  label,
  helperText,
  required,
  selectDefaultValue,
  children,
  ...rest
}: HookedFormFieldPropsType) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          error={error ? true : false}
          id={name}
          required={required}
          label={label}
          defaultValue={selectDefaultValue}
          helperText={error ? error?.message : helperText}
          {...rest}
        >
          {children}
        </TextField>
      )}
    />
  );
};

export default HookedFormSelectField;
