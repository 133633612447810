import { useMemo } from "react";
import UserCell from "../Persons/components/UserCell";
import { MRT_ColumnDef } from "material-react-table";
import { EmailType } from "../../types/Email";
import PersonsBadgesWidget from "../../components/MuiTable/PersonsBadgesWidget";
import DeliveryStatusCell from "./components/DeliveryStatusCell";

export const useGetColumns = (status: string, isLoading: boolean) => {
  const columnsSent = useMemo<MRT_ColumnDef<EmailType>[]>(
    () => [
      {
        accessorKey: "subject",
        header: "Subject",
      },
      {
        accessorFn: (row) => {
          if (row.updated_by) {
            return <UserCell userId={row.updated_by} />;
          }
          return "Unknown";
        },
        header: "Updated by",
      },
      {
        accessorFn: (row) => {
          if (row.sent_by) {
            return <UserCell userId={row.sent_by} />;
          }
          return "Unknown";
        },
        header: "Sent by",
      },
      {
        accessorFn: (row) => {
          const date = row.updated_at;
          return date ? new Date(date).toLocaleDateString("en-GB") : "";
        },
        header: "Date updated",
      },
      {
        accessorFn: (row) => {
          if (row.status === "sent") {
            return <DeliveryStatusCell emailId={row.id} />;
          }
          return "Unknown";
        },
        header: "Delivered/Opened",
      },
      {
        header: "Recipients",
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        showColumnFilters: false,
        Cell: ({ row }) =>
          row.original.recipients && row.original.recipients.length ? (
            <PersonsBadgesWidget ids={row.original.recipients} />
          ) : null,
        Filter: () => null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const columnsDraft = useMemo<MRT_ColumnDef<EmailType>[]>(
    () => [
      {
        accessorKey: "subject",
        header: "Subject",
      },
      {
        accessorFn: (row) => {
          if (row.created_by) {
            return <UserCell userId={row.created_by} />;
          }
          return "Unknown";
        },
        header: "Created by",
      },
      {
        accessorFn: (row) => {
          if (row.updated_by) {
            return <UserCell userId={row.updated_by} />;
          }
          return "Unknown";
        },
        header: "Updated by",
      },
      {
        accessorFn: (row) => {
          const date = row.updated_at;
          return date ? new Date(date).toLocaleDateString("en-GB") : "";
        },
        header: "Date updated",
      },
      {
        header: "Recipients",
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        showColumnFilters: false,
        Cell: ({ row }) =>
          row.original.recipients && row.original.recipients.length ? (
            <PersonsBadgesWidget ids={row.original.recipients} />
          ) : null,
        Filter: () => null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return status === "sent" ? columnsSent : columnsDraft;
};
