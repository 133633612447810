import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { PersonDetail, PersonInfo } from "../types/Person";
import { useCreatePersonInfo, useUpdatePersonInfo } from "../query/person";
import { useForm } from "react-hook-form";
import ModalLayout from "./ModalLayout";

type EditSalutationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  person: PersonDetail;
  personInfo?: PersonInfo;
};

const EditSalutationModal = ({
  open,
  setOpen,
  person,
  personInfo,
}: EditSalutationModalProps) => {
  const { mutate: updateSalutation } = useUpdatePersonInfo();
  const { mutate: createSalutation } = useCreatePersonInfo();

  const { register, getValues, handleSubmit } = useForm({
    defaultValues: {
      updated_salutation: personInfo?.salutation || person.salutation || "",
    },
  });
  const onSubmit = () => {
    if (personInfo) {
      updateSalutation(
        {
          id: person.id,
          data: { salutation: getValues("updated_salutation") },
        },
        {
          onSuccess: () => {
            setOpen(!open);
          },
        },
      );
    } else {
      createSalutation(
        {
          id: person.id,
          data: { salutation: getValues("updated_salutation") },
        },
        {
          onSuccess: () => {
            setOpen(!open);
          },
        },
      );
    }
  };

  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} pt={3}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h2"} pb={2}>
              Edit Salutation
            </Typography>
            <CloseIcon onClick={() => setOpen(false)} />
          </Stack>

          <TextField
            disabled
            id="current-salutation"
            label={`What we call ${person.name}`}
            defaultValue={personInfo?.salutation || person.salutation}
          />
          <TextField
            {...register("updated_salutation")}
            id="updated-salutation"
            label={`What you want to call ${person.name}`}
            defaultValue={personInfo?.salutation || person.salutation}
          />

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            py={2}
            gap={1}
            sx={{
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button variant="outlined" onClick={() => setOpen(!open)}>
              Cancel{" "}
            </Button>
            <Button variant="contained" type="submit">
              Confirm
            </Button>
          </Box>
        </Stack>
      </form>
    </ModalLayout>
  );
};

export default EditSalutationModal;
