import { Box, IconButton, Stack, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ContentWidgetContent } from "../types/Search";
import { formatDate, truncateText } from "../services/utils";

interface ContentCardProps {
  content: ContentWidgetContent;
}
// TODO: refactor to use mui Card
const ContentCard = ({ content }: ContentCardProps) => {
  return (
    <Box
      border={1}
      borderColor="divider"
      borderRadius="8px"
      sx={{ background: "white" }}
      py={2}
      px={3}
      key={`${content.content_type}-${content.data_key}`}
    >
      <Stack spacing={1.5} direction={"column"}>
        <Box
          gap={3}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems={"center"}>
            <Box gap={2} display={"flex"} alignItems={"center"}>
              <Box
                component={"img"}
                src={content?.type?.img}
                width={"30px"}
                height="30px"
              ></Box>
              <Typography variant="subtitle2" color="text.secondary">
                {content?.type?.name}
              </Typography>
            </Box>
            <Typography color="text.secondary" variant="subtitle2" px={5}>
              {formatDate(content?.created_at)}
            </Typography>
          </Box>
          <IconButton aria-label="more" aria-haspopup="true">
            <MoreHorizIcon />
          </IconButton>
        </Box>

        <Typography variant="h3">{content?.title}</Typography>
        <Typography variant="body1">
          {truncateText(content.text, 400)}
        </Typography>
        {/* <Typography variant="subtitle2" color="primary">
          {content?.contributions_count} contributions
        </Typography> */}
      </Stack>
    </Box>
  );
};

export default ContentCard;
