import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { memo, useState } from "react";
import { Control, Controller } from "react-hook-form";

type CollapsableRowProps = {
  header: string;
  id: string;
  children?: any;
  collapsable?: boolean;
  opened?: boolean;
  control?: Control<Record<string, boolean>>;
  setFilterRefresh?: any;
};

const CollapsableRow = ({
  children,
  header,
  collapsable = true,
  id,
  opened,
  control,
  setFilterRefresh,
}: CollapsableRowProps) => {
  const [isOpen, setOpen] = useState(opened);
  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <Box id={`${id}_${isOpen}`}>
      <ListItemButton onClick={handleClick}>
        {collapsable ? (
          <ListItemIcon sx={{ minWidth: 0 }}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        ) : null}

        <Controller
          control={control}
          name={id}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              sx={{ display: collapsable ? "none" : "block" }}
              onClick={(event) => {
                event.stopPropagation();
                setFilterRefresh(Math.random());
              }}
            />
          )}
        />
        <ListItemText
          primary={header}
          primaryTypographyProps={{ variant: "subtitle2", color: "black" }}
        />
      </ListItemButton>
      {collapsable ? (
        <Collapse in={isOpen} unmountOnExit>
          {children}
        </Collapse>
      ) : null}
    </Box>
  );
};

export default memo(CollapsableRow);
