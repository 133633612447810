import { getAccessToken } from "./AuthService";
import { NotificationService } from "./NotificationService";
import { LocalStorageKey, StorageService } from "./StorageService";
import { getBasePath } from "./utils";

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Patch = "PATCH",
  Delete = "DELETE",
}

interface IApiData {
  data?: any;
}

export const fetcher = async <T = any,>(
  method: HttpMethod,
  url: string,
  data?: any,
): Promise<{ response: Response; result: T }> => {
  const reqHeader = new Headers();
  const accessToken = StorageService.Get(LocalStorageKey.Jwt);
  if (accessToken) {
    reqHeader.append("Authorization", `Bearer ${accessToken}`);
  }
  let body = data;
  const isFormData = data instanceof FormData;

  if (data && !isFormData) {
    reqHeader.append("Accept", "application/json");
    reqHeader.append("Content-Type", "application/json");
    body = JSON.stringify(data);
  }
  const response: Response = await fetch(getBasePath() + url, {
    method,
    body,
    headers: reqHeader,
  });

  const contentType = response.headers.get("Content-Type");
  let result;

  try {
    if (contentType?.includes("text")) {
      result = await response.text();
    } else {
      result = await response.json();
    }
  } catch (e) {
    console.log(e);
  }
  return { response, result };
};

export const secureRequest = async (
  method: HttpMethod,
  url: string,
  { data }: IApiData,
  silent?: boolean,
): Promise<any> => {
  const { response, result } = await fetcher(method, url, data);
  if (response.status >= 400) {
    if (response.status === 401) {
      try {
        await getAccessToken();
      } catch (e) {
        return;
      }
      return secureRequest(method, url, { data });
    }
    if (response.status === 422 && !silent) {
      result.detail.forEach((error: any) => {
        NotificationService.Error(`${error.msg}: ${error.loc[1]}`);
      });
      throw new Error(`Validation error: ${result.detail}`);
    } else if (!silent) {
      NotificationService.Error(result.detail);
      throw new Error(`API Error: ${result.detail}`);
    } else {
      return "";
    }
  } else {
    return result;
  }
};

export const fileFetcher = async (
  method: HttpMethod,
  url: string,
  data?: any,
): Promise<{ response: Response; result: Response }> => {
  const reqHeader = new Headers();
  const accessToken = StorageService.Get(LocalStorageKey.Jwt);
  if (accessToken) {
    reqHeader.append("Authorization", `Bearer ${accessToken}`);
  }
  let body = data;
  const isFormData = data instanceof FormData;

  if (data && !isFormData) {
    reqHeader.append("Accept", "application/json");
    reqHeader.append("Content-Type", "application/json");
    body = JSON.stringify(data);
  }

  const response: Response = await fetch(getBasePath() + url, {
    method,
    body,
    headers: reqHeader,
  });

  return { response, result: response };
};

export const secureFileDownload = async (
  method: HttpMethod,
  url: string,
  { data }: IApiData,
): Promise<any> => {
  const { response, result } = await fileFetcher(method, url, data);
  if (response.status >= 400) {
    if (response.status === 401) {
      try {
        await getAccessToken();
      } catch (e) {
        return;
      }
      return secureFileDownload(method, url, { data });
    }
  } else {
    return result;
  }
};

export const getTestApi = async () => {
  return secureRequest(HttpMethod.Get, "/auth/test", {});
};
