import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { CreateNoteFormType, Note } from "../types/Notes";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import FileInputWithModal from "./FileInput";

import AttachmentLinkWidget from "./AttachmentsLinkWidget";
import CKEditorComponent from "./CKEditorComponent";
import ModalLayout from "./ModalLayout";

export interface NoteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSubmitApi: (data: CreateNoteFormType) => void;
  header: string;
  note?: Note;
}

const NOTE_TYPES = [
  "Letter in",
  "Letter out",
  "Email in",
  "Email out",
  "Telephone call in",
  "Telephone call out",
  "Meeting",
  "Coverage",
  "Social media interaction",
  "Event",
  "Information request",
  "Briefing",
  "Site visit",
  "Other",
];

const NoteModal = ({
  open,
  setOpen,
  note,
  handleSubmitApi,
  header,
}: NoteModalProps) => {
  const { register, handleSubmit, getValues, setValue, watch } =
    useForm<CreateNoteFormType>({
      defaultValues: {
        note_title: note ? note.title : "",
        note_type: note ? note.note_type : "",
        note_content: note ? note.text : "",
        attachments: note ? (note.attachments as string[]) : [],
      },
    });
  const is_email_out = note?.note_type === "Email out";
  const onSubmit = () => {
    const inputData = getValues();
    // Compatibility with existing model
    handleSubmitApi(inputData);
  };

  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} spacing={1} pt={2} pb={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant={"h2"} pb={2}>
                {header}
              </Typography>
              <CloseIcon onClick={() => setOpen(!open)} />
            </Stack>

            <Stack direction={"column"} spacing={1}>
              <Typography variant="h4">Note title</Typography>
              <TextField
                {...register("note_title", { required: true })}
                id="outlined-required"
                size="small"
                disabled={is_email_out}
                defaultValue={
                  getValues("note_title")
                    ? getValues("note_title")
                    : "Enter note title"
                }
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography variant="h4">Note type</Typography>
              <Select
                {...register("note_type", { required: true })}
                size="small"
                disabled={is_email_out}
                defaultValue={
                  getValues("note_type")
                    ? getValues("note_type").toLowerCase()
                    : ""
                }
              >
                <MenuItem key={"clear"} value={undefined}>
                  Clear selection
                </MenuItem>
                {NOTE_TYPES.map((note_type, ind) => {
                  return (
                    <MenuItem key={ind} value={note_type.toLowerCase()}>
                      {note_type}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography variant="h4">Note content</Typography>
              <Box
                sx={{
                  ".ck-editor__editable": {
                    height: "200px",
                  },
                }}
              >
                <CKEditorComponent
                  data={
                    note
                      ? `${getValues("note_content")}`
                      : `<p>Input your note here</p>`
                  }
                  disabled={is_email_out}
                  onReady={register}
                  onChange={setValue}
                  bodyName="note_content"
                />
              </Box>
            </Stack>
            {watch("attachments") ? (
              <Box>
                {watch("attachments").map((att, ind) => {
                  return <AttachmentLinkWidget key={ind} attachmentId={att} />;
                })}
              </Box>
            ) : null}
            {!is_email_out ? (
              <Stack direction={"column"} spacing={1}>
                <Typography variant="h4">Select file to upload</Typography>
                <FileInputWithModal
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                />
              </Stack>
            ) : null}
          </Stack>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            pt={2}
            pb={2}
            gap={1}
            sx={{
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpen(!open)}
            >
              Cancel{" "}
            </Button>
            <Button variant="contained" size="small" type="submit">
              Confirm
            </Button>
          </Box>
        </form>
      </Box>
    </ModalLayout>
  );
};

export default NoteModal;
