import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { useCreatePersonInfo, useUpdatePersonInfo } from "../query/person";
import { PersonInfo } from "../types/Person";
import ModalLayout from "./ModalLayout";

type PersonNoteModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  personInfo?: PersonInfo;
  personId: string;
};

const PersonNoteModal = ({
  open,
  setOpen,
  personInfo,
  personId,
}: PersonNoteModalProps) => {
  const { mutate: updateNoteText } = useUpdatePersonInfo();
  const { mutate: createNoteText } = useCreatePersonInfo();

  const { register, getValues, handleSubmit } = useForm({
    defaultValues: {
      textNote: personInfo?.text_note,
    },
  });
  const onSubmit = () => {
    if (personInfo) {
      updateNoteText(
        { id: personId, data: { text_note: getValues("textNote") } },
        {
          onSuccess: () => {
            setOpen(!open);
          },
        },
      );
    } else {
      createNoteText(
        { id: personId, data: { text_note: getValues("textNote") } },
        {
          onSuccess: () => {
            setOpen(!open);
          },
        },
      );
    }
  };

  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} pt={3} display="flex">
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h2"} pb={2}>
              Custom Note
            </Typography>
            <CloseIcon onClick={() => setOpen(false)} />
          </Stack>
          <Typography variant="h4">Note content</Typography>
          <TextField
            {...register("textNote")}
            id="outlined-required"
            multiline
            defaultValue={
              personInfo?.text_note ? personInfo?.text_note : "Enter text here"
            }
            rows={24}
          />

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            py={2}
            gap={1}
            sx={{
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel{" "}
            </Button>
            <Button variant="contained" type="submit">
              Confirm
            </Button>
          </Box>
        </Stack>
      </form>
    </ModalLayout>
  );
};

export default PersonNoteModal;
